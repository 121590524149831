import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Papa from 'papaparse';
import {parse, stringify, toJSON, fromJSON} from 'flatted';

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	ButtonDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
	Fade
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

var globalFiltered;
var globalSortedData;

class InventoryManagementList extends React.Component {

	intervalID = 0;

	selectTable1;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

		const availableUsers = [
		{
			id: 0,
			name: "Alex Di Sessa",
			email: "adisessa@mac.com"
		},
		{
			id: 1,
			name: "Hilary Brown",
			email: "hbrown@azenamedical.com"
		},
		{
			id: 2,
			name: "Jacob Abe",
			email: "jabe@azenamedical.com"
		},
		{
			id: 3,
			name: "Jin Lee",
			email: "jlee@azenamedical.com"
		},
		{
			id: 4,
			name: "Jonathan Luo",
			email: "jluo@azenamedical.com"
		},
		{
			id: 5,
			name: "Nestor Francisco",
			email: "nfrancisco@azenamedical.com"
		},
		{
			id: 6,
			name: "Roberto Imai",
			email: "rimai@azenamedical.com"
		},
		{
			id: 7,
			name: "Robin Duysen",
			email: "rduysen@azenamedical.com"
		},
		{
			id: 8,
			name: "Ryan Stickel",
			email: "ryan.a.stickel@gmail.com"
		},
		{
			id: 9,
			name: "Armando Angeles",
			email: "aangeles@azenamedical.com"
		}];

		//Testing
		//let userEmail = "rduysen@azenamedical.com";

		let currentAvailableUser = availableUsers.find(u => u.email === currentUser.payload.email);

    this.state = {
			bomData: [],
			ausBomData: [],
			europeBomData: [],
			brazilBomData: [],
			taiwanBomData: [],
			colombiaBomData: [],
			assetData: [],
			productsData: [],
			marketsData: [],
			productItemsForBOM: [],
			quantitiesChanged: [],
			obj: {},
			marketSelected: 0,
			modal1: false,
			modal2: false,
			modal3: false,
			modal7: false,
			modal8: false,
			modal11: false,
			isOpen: false,
			editPassword: "Azena589",
			currentQuantityOfItem: 0,
			currentItem: "ALL",
			currentDataSetting: 0,
			reasonAdjustmentSelection: 0,
			submitStatus: '',
			newPartNumber: "",
			newCost: "",
			newDescription: "",
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			isOpen2: false,
			diableQuantityFlag: false,
			adjustmentReasons: [{
				name: "Associated Project"
			},{
				name: "R & D"
			},{
				name: "Purchase (For Positive)"
			},{
				name: "Inventory Adjustment (Shortage)"
			},{
				name: "Inventory Adjustment (Overage)"
			},{
				name: "Repair Kit"
			}],
			currentItem2: currentAvailableUser ? currentAvailableUser.name : availableUsers[0].name,
			currentDataSetting2: currentAvailableUser ? currentAvailableUser.id : 0,
			availableUsers
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
		this.onRadioBtnClick2 = this.onRadioBtnClick2.bind(this);

		this.addQuantity = this.addQuantity.bind(this);
		this.subtractQuantity = this.subtractQuantity.bind(this);

		this.toggle1 = this.toggle1.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.toggle3 = this.toggle3.bind(this);
		this.toggle7 = this.toggle7.bind(this);
		this.toggle8 = this.toggle8.bind(this);
		this.toggle11 = this.toggle11.bind(this);

		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.toggleDropDown2 = this.toggleDropDown2.bind(this);
		this.costOrPartNumberChange = this.costOrPartNumberChange.bind(this);
		this.onChangeAuthPassword = this.onChangeAuthPassword.bind(this);


  }


	async componentDidMount() {
		this.getProductsData();
		this.getMarketsData();
		await this.getBOMData(this.state.marketSelected);
		await this.getBOMData(1);
		await this.getBOMData(2);
		await this.getBOMData(3);
		await this.getBOMData(4);
		await this.getBOMData(5);

		await this.getAssetData();
		//this.intervalID = setInterval(this.getBOMData, 5000);
	}

	componentWillUnmount() {
		//console.log("Laser-Usage componentWillUnmount");
		clearInterval(this.intervalID);
	}

	async onRadioBtnClick(marketSelected) {

		await this.setState({ marketSelected });

		if (marketSelected==99) {
			this.getAssetData();
		} else {
			this.getBOMData(marketSelected);
		}

	}

	async onRadioBtnClick2(currentDataSetting) {

		await this.setState({ currentDataSetting });

		setTimeout(() => this.getBOMData(this.state.marketSelected), 300);

	}

	toggle1() {
	  this.setState({
	    modal1: !this.state.modal1
	  });
	}

	toggle2() {
	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle3() {
	  this.setState({
	    modal3: !this.state.modal3
	  });
	}

	toggle7() {
	  this.setState({
	    modal7: !this.state.modal7
	  });
	}

	toggle8() {
	  this.setState({
	    modal8: !this.state.modal8
	  });
	}

	toggle11() {
	  this.setState({
	    modal11: !this.state.modal11
	  });
	}

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		setTimeout(this.getBOMData, 300);

  }

	toggleDropDown2() {
    this.setState({
      isOpen2: !this.state.isOpen2
    });
  }

	selectDropDownItem2(id, title) {

    this.setState({
      currentItem2: title,
			currentDataSetting2: id
    });

  }

	addQuantity() {

		console.log('addQuantity');

		this.setState({
      currentQuantityOfItem: this.state.currentQuantityOfItem+1
    });
	}

	subtractQuantity() {

		console.log('subtractQuantity');

		if (this.state.currentQuantityOfItem>0) {
			this.setState({
	      currentQuantityOfItem: this.state.currentQuantityOfItem-1
	    });
		}

	}

	getProductsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-products";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Products data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productsData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getMarketsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-markets";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all markets data: " + JSON.stringify(body));

			if (body.status == 'Success') {

				//let userMarket = this.state.currentUser.payload['custom:market'];
				var marketsData = body.response;

				// if (userMarket=='aus' || userMarket=='europe') {
				// 	marketsData = marketsData.filter(market => market.Abbreviation.includes(userMarket))
				// }

				this.setState({ marketsData });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getBOMData = async (marketSelected) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-bill-of-materials?ProductID="+this.state.currentDataSetting+"&MarketID="+marketSelected+"&QueryType=1";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all BOM data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				if (marketSelected==0) {
					console.log("all bomData");
					this.setState({ bomData: body.response });
				} else if (marketSelected==1) {
					console.log("all europeBomData");
					this.setState({ europeBomData: body.response });
				} else if (marketSelected==2) {
					console.log("all ausBomData");
					this.setState({ ausBomData: body.response });
				} else if (marketSelected==3) {
					console.log("all brazilBomData");
					this.setState({ brazilBomData: body.response });
				} else if (marketSelected==4) {
					console.log("all taiwanBomData");
					this.setState({ taiwanBomData: body.response });
				} else if (marketSelected==5) {
					console.log("all colombiaBomData");
					this.setState({ colombiaBomData: body.response });
				}

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getAssetData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-asset-inventory";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Asset data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ assetData: body.response });

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	createBOMSubmit = (event) => {
		event.preventDefault();

    let partNumber = event.target.PartNumber.value;
    console.log(`partNumber is:`, partNumber);

		let cost = event.target.Cost.value;
    console.log(`cost is:`, cost);

		let quantity = event.target.Quantity.value;
    console.log(`quantity is:`, quantity);

		let description = event.target.Description.value;
    console.log(`description is:`, description);

		let quantityFlag = event.target.QuantityFlag ? event.target.QuantityFlag.value : 0;
    console.log(`quantityFlag is:`, quantityFlag);

		let ignoreQuantityFlag = event.target.IgnoreQuantityFlag && event.target.IgnoreQuantityFlag.checked ? 1 : 0;
    console.log(`ignoreQuantityFlag is:`, ignoreQuantityFlag);

		//let products = event.target.Products.value;
		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

		let adjustmentReasonItem = "New Part";

		let inventoryChangeBody = {
			PartNumber: partNumber,
			Cost: cost,
			PreviousQuantity: "0",
			CurrentQuantity: quantity,
			Description: description,
			UserFullName: this.state.availableUsers[this.state.currentDataSetting2].name,
			UserEmail: this.state.availableUsers[this.state.currentDataSetting2].email,
			AdjustmentReason: adjustmentReasonItem
		}

		this.logInventoryChange(inventoryChangeBody);

		this.setState({
			modal11: !this.state.modal11
		})

    this.createBOM(partNumber, cost, quantity, description, products, quantityFlag, ignoreQuantityFlag);
	}

  createBOM = async (partNumber, cost, quantity, description, products, quantityFlag, ignoreQuantityFlag) => {

		var marketSelected = this.state.marketSelected;

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-bill-of-materials?QuantityFlag="+quantityFlag+"&MarketID="+marketSelected+"&PartNumber="+partNumber+"&Cost="+cost+"&Description="+description+"&Quantity="+quantity+"&IgnoreQuantityFlag="+ignoreQuantityFlag;

		//console.log("create BOM marketSelected: " + JSON.stringify(marketSelected));

		console.log("create BOM url: " + JSON.stringify(url));

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create BOM response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				var bomID = body.response.insertId;

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForBOM(bomID, product);
				}

				// products.map((product, i) => {
				// 	this.addProductItemForBOM(bomID, product);
				// });

				toast.success("Part created successfully.", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					closeButton: false
				});

				this.getBOMData(this.state.marketSelected);
			} else {
				//this.state.noDataForDevices = true;

				//console.log("Error creating BOM response: " + JSON.stringify(body));

				toast.error("Error creating part. Missing required fields.", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					closeButton: false
				});
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};


	createAssetSubmit = (event) => {
		event.preventDefault();

    let partNumber = event.target.PartNumber.value;
    console.log(`partNumber is:`, partNumber);

		let serialNumber = event.target.SerialNumber.value;
    console.log(`partNumber is:`, partNumber);

		let description = event.target.Description.value;
    console.log(`description is:`, description);

		let cost = event.target.Cost.value;
    console.log(`cost is:`, cost);

		let quantity = event.target.Quantity.value;
    console.log(`quantity is:`, quantity);

		let marketID = event.target.MarketID.value;
    console.log(`marketID is:`, marketID);

		let calibrationDate = event.target.CalibrationDate.value;
    console.log(`calibrationDate is:`, calibrationDate);

		let calibrationPeriod = event.target.CalibrationPeriod.value;
    console.log(`calibrationPeriod is:`, calibrationPeriod);

		let deliveryDate = event.target.DeliveryDate.value;
    console.log(`deliveryDate is:`, deliveryDate);

		this.setState({
			modal1: !this.state.modal1
		})

    this.createAsset(partNumber, serialNumber, description, cost, quantity, marketID, calibrationDate, calibrationPeriod, deliveryDate);
	}

  createAsset = async (partNumber, serialNumber, description, cost, quantity, marketID, calibrationDate, calibrationPeriod, deliveryDate) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-asset-inventory-item?PartNumber="+partNumber+"&SerialNumber="+serialNumber+"&Description="+description+"&Cost="+cost+"&Quantity="+quantity+"&MarketID="+marketID+"&CalibrationDate="+calibrationDate+"&CalibrationPeriod="+calibrationPeriod+"&DeliveryDate="+deliveryDate;

		//console.log("create BOM marketSelected: " + JSON.stringify(marketSelected));

		//console.log("create BOM url: " + JSON.stringify(url));

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create BOM response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				toast.success("Asset created successfully.", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					closeButton: false
				});

				this.getAssetData();

			} else {
				//this.state.noDataForDevices = true;

				//console.log("Error creating BOM response: " + JSON.stringify(body));

				toast.error("Error creating asset. Missing required fields.", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					closeButton: false
				});
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	editAssetSubmit = (event) => {
		event.preventDefault();

		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let assetID = event.target.assetID.value;
    console.log(`assetID is:`, assetID);

    let partNumber = event.target.PartNumber.value;
    console.log(`partNumber is:`, partNumber);

		let serialNumber = event.target.SerialNumber.value;
    console.log(`partNumber is:`, partNumber);

		let description = event.target.Description.value;
    console.log(`description is:`, description);

		let cost = event.target.Cost.value;
    console.log(`cost is:`, cost);

		let quantity = event.target.Quantity.value;
    console.log(`quantity is:`, quantity);

		let marketID = event.target.MarketID.value;
    console.log(`marketID is:`, marketID);

		let calibrationDate = event.target.CalibrationDate.value;
    console.log(`calibrationDate is:`, calibrationDate);

		let calibrationPeriod = event.target.CalibrationPeriod.value;
    console.log(`calibrationPeriod is:`, calibrationPeriod);

		let deliveryDate = event.target.DeliveryDate.value;
    console.log(`deliveryDate is:`, deliveryDate);

		this.setState({
			modal2: !this.state.modal2
		})

    this.editAsset(assetID, partNumber, serialNumber, description, cost, quantity, marketID, calibrationDate, calibrationPeriod, deliveryDate);
	}

  editAsset = async (assetID, partNumber, serialNumber, description, cost, quantity, marketID, calibrationDate, calibrationPeriod, deliveryDate) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-asset-inventory-item?AssetID="+assetID+"&PartNumber="+partNumber+"&SerialNumber="+serialNumber+"&Description="+description+"&Cost="+cost+"&Quantity="+quantity+"&MarketID="+marketID+"&CalibrationDate="+calibrationDate+"&CalibrationPeriod="+calibrationPeriod+"&DeliveryDate="+deliveryDate;

		//console.log("create BOM marketSelected: " + JSON.stringify(marketSelected));

		//console.log("create BOM url: " + JSON.stringify(url));

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create BOM response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				toast.success("Asset edited successfully.", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					closeButton: false
				});

				this.getAssetData();

			} else {
				//this.state.noDataForDevices = true;

				//console.log("Error creating BOM response: " + JSON.stringify(body));

				toast.error("Error editing asset. Missing required fields.", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					closeButton: false
				});
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteAsset = async () => {

		console.log("Delete Asset: " + this.state.obj.PartNumber);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-asset-inventory-item?AssetID="+this.state.obj.assetID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete Asset response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle3();
				this.getAssetData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	logInventoryChange = async (inventoryChangeBody) => {

		console.log("Log Inventory Change fields: " + JSON.stringify(inventoryChangeBody));

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"log-inventory-change";

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: this.state.currentUser.jwtToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(inventoryChangeBody)
			});

			const body = await response.json();
			console.log("Log Inventory Change response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {

			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleBOMSubmit = (event) => {
		event.preventDefault();

		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let bomID = event.target.bomID.value;
    console.log(`bomID is:`, bomID);
    let partNumber = event.target.PartNumber.value;
    console.log(`partNumber is:`, partNumber);
		let cost = event.target.Cost.value;
    console.log(`cost is:`, cost);
		let previousQuantity = event.target.OriginalQuantity.value;
    console.log(`quantity is:`, quantity);
		let quantity = event.target.NewQuantity.value;
    console.log(`quantity is:`, quantity);
		let description = event.target.Description.value;
    console.log(`description is:`, description);
		let quantityFlag = event.target.QuantityFlag ? event.target.QuantityFlag.value : 0;
    console.log(`quantityFlag is:`, quantityFlag);
		let ignoreQuantityFlag = event.target.IgnoreQuantityFlag && event.target.IgnoreQuantityFlag.checked ? 1 : 0;
    console.log(`quantityFlag is:`, quantityFlag);

		if (this.state.marketSelected==0) {
			var adjustmentReason = event.target.AdjustmentReason.value;
	    console.log(`adjustmentReason is:`, adjustmentReason);

			if (adjustmentReason==0) {
				var submitStatus = "You must select an adjustment reason.";
				this.setState({ submitStatus });
			} else {
				let newQuantitiesChanged = JSON.parse(JSON.stringify(this.state.quantitiesChanged));

				var bomIDNumber = parseInt(bomID);

				//quantitiesChanged
				if (previousQuantity != quantity) {

					let quantitiesChangedItem = newQuantitiesChanged.find(q => q.bomID === bomIDNumber);

					if (quantitiesChangedItem) {

						console.log("found quantitiesChangedItem is:", quantitiesChangedItem);

						newQuantitiesChanged[id] = {
							bomID: bomIDNumber,
							previousQuantity,
							currentQuantity: quantity
				    }

						//console.log("found quantitiesChangedItem is:", quantitiesChangedItem);

					} else {
						var newQuantitiesChangedItem = {
							bomID: bomIDNumber,
							previousQuantity,
							currentQuantity: quantity
						};

						newQuantitiesChanged.push(newQuantitiesChangedItem);
					}

					console.log("quantitiesChanged is:", newQuantitiesChanged);
					this.setState({ quantitiesChanged: newQuantitiesChanged });

					let adjustmentReasonItem = "";

					if (this.state.reasonAdjustmentSelection==(this.state.adjustmentReasons.length+1)) {
						let otherAdjustmentReason = event.target.OtherAdjustmentReason.value;
				    console.log(`OtherAdjustmentReason is:`, adjustmentReason);
						adjustmentReasonItem = "Other - " + otherAdjustmentReason;
					} else {
						adjustmentReasonItem = this.state.adjustmentReasons[adjustmentReason-1].name;
					}

					console.log("adjustmentReasonItem name is:", adjustmentReasonItem);

					let inventoryChangeBody = {
						PartNumber: partNumber,
						Cost: cost,
						PreviousQuantity: previousQuantity,
						CurrentQuantity: quantity,
						Description: description,
						UserFullName: this.state.availableUsers[this.state.currentDataSetting2].name,
						UserEmail: this.state.availableUsers[this.state.currentDataSetting2].email,
						AdjustmentReason: adjustmentReasonItem
					}

					this.logInventoryChange(inventoryChangeBody);

				}

				let products = Array.from(event.target.Products.selectedOptions, option => option.value);
		    console.log(`products is:`, products);

				if (this.state.obj.PartNumber!=this.state.newPartNumber || this.state.obj.Cost!=this.state.newCost || this.state.obj.Description!=this.state.newDescription) {
					let authPassword = event.target.AuthPassword.value;
			    console.log(`authPassword is:`, authPassword);

					if (authPassword == this.state.editPassword) {
						console.log(`Change is Authorized`);

						//console.log(`role is:`, role);
						let newObj = JSON.parse(JSON.stringify(this.state.bomData));
				    //console.log(`user copy is:`, newObj);
						//newObj[id] = [firstname, lastname, email, role];
				    newObj[id] = {
							PartNumber: partNumber,
							Cost: cost,
							Quantity: quantity,
							Description: description
				    }
						this.setState({
							bomData: newObj,
							modal7: !this.state.modal7
						})
				    console.log("saved bomData is:", this.state.bomData[id].PartNumber);

						this.updateBOM(bomID, partNumber, cost, quantity, description, products, quantityFlag, ignoreQuantityFlag);

					} else {
						console.log(`Change is NOT Authorized`);
						var submitStatus = "Incorrect Password. Password is required for changes to: Part Number, Description, Cost";
						this.setState({ submitStatus });
					}

				} else {

					//console.log(`role is:`, role);
					let newObj = JSON.parse(JSON.stringify(this.state.bomData));
			    //console.log(`user copy is:`, newObj);
					//newObj[id] = [firstname, lastname, email, role];
			    newObj[id] = {
						PartNumber: partNumber,
						Cost: cost,
						Quantity: quantity,
						Description: description
			    }
					this.setState({
						bomData: newObj,
						modal7: !this.state.modal7
					})
			    console.log("saved bomData is:", this.state.bomData[id].PartNumber);

					this.updateBOM(bomID, partNumber, cost, quantity, description, products, quantityFlag, ignoreQuantityFlag);

				}
			}

		} else {

			let newQuantitiesChanged = JSON.parse(JSON.stringify(this.state.quantitiesChanged));

			var bomIDNumber = parseInt(bomID);

			//quantitiesChanged
			if (previousQuantity != quantity) {

				let quantitiesChangedItem = newQuantitiesChanged.find(q => q.bomID === bomIDNumber);

				if (quantitiesChangedItem) {

					console.log("found quantitiesChangedItem is:", quantitiesChangedItem);

					newQuantitiesChanged[id] = {
						bomID: bomIDNumber,
						previousQuantity,
						currentQuantity: quantity
					}

					//console.log("found quantitiesChangedItem is:", quantitiesChangedItem);

				} else {
					var newQuantitiesChangedItem = {
						bomID: bomIDNumber,
						previousQuantity,
						currentQuantity: quantity
					};

					newQuantitiesChanged.push(newQuantitiesChangedItem);
				}

				console.log("quantitiesChanged is:", newQuantitiesChanged);
				this.setState({ quantitiesChanged: newQuantitiesChanged });

				let adjustmentReasonItem = "INTL inventory change";

				console.log("adjustmentReasonItem name is:", adjustmentReasonItem);

				let inventoryChangeBody = {
					PartNumber: partNumber,
					Cost: cost,
					PreviousQuantity: previousQuantity,
					CurrentQuantity: quantity,
					Description: description,
					UserFullName: this.state.availableUsers[this.state.currentDataSetting2].name,
					UserEmail: this.state.availableUsers[this.state.currentDataSetting2].email,
					AdjustmentReason: adjustmentReasonItem,
					MarketID: this.state.marketSelected
				}

				console.log(`inventoryChangeBody is:`, JSON.stringify(inventoryChangeBody));
				this.logInventoryChange(inventoryChangeBody);

			}

			let products = Array.from(event.target.Products.selectedOptions, option => option.value);
			console.log(`products is:`, products);

			//console.log(`this.state.marketSelected is:`, JSON.stringify(this.state.marketSelected));
			var newObj = [];

			if (this.state.marketSelected==1) {
				newObj = JSON.parse(JSON.stringify(this.state.europeBomData));
			} else if (this.state.marketSelected==2) {
				newObj = JSON.parse(JSON.stringify(this.state.ausBomData));
			} else if (this.state.marketSelected==3) {
				newObj = JSON.parse(JSON.stringify(this.state.brazilBomData));
			} else if (this.state.marketSelected==4) {
				newObj = JSON.parse(JSON.stringify(this.state.taiwanBomData));
			} else if (this.state.marketSelected==5) {
				newObj = JSON.parse(JSON.stringify(this.state.colombiaBomData));
			}

			console.log(`marketSelected copy is:`, newObj);
			//newObj[id] = [firstname, lastname, email, role];
			newObj[id] = {
				PartNumber: partNumber,
				Cost: cost,
				Quantity: quantity,
				Description: description
			}

			if (this.state.marketSelected==1) {
				this.setState({
					europeBomData: newObj,
					modal7: !this.state.modal7
				})
				console.log("saved europeBomData is:", this.state.europeBomData[id].PartNumber);
			} else if (this.state.marketSelected==2) {
				this.setState({
					ausBomData: newObj,
					modal7: !this.state.modal7
				})
				console.log("saved ausBomData is:", this.state.ausBomData[id].PartNumber);
			} else if (this.state.marketSelected==3) {
				this.setState({
					brazilBomData: newObj,
					modal7: !this.state.modal7
				})
				console.log("saved brazilBomData is:", this.state.brazilBomData[id].PartNumber);
			} else if (this.state.marketSelected==4) {
				this.setState({
					taiwanBomData: newObj,
					modal7: !this.state.modal7
				})
				console.log("saved taiwanBomData is:", this.state.taiwanBomData[id].PartNumber);
			} else if (this.state.marketSelected==5) {
				this.setState({
					colombiaBomData: newObj,
					modal7: !this.state.modal7
				})
				console.log("saved colombiaBomData is:", this.state.colombiaBomData[id].PartNumber);
			}

			this.updateBOM(bomID, partNumber, cost, quantity, description, products, quantityFlag, ignoreQuantityFlag);

		}

	}

  updateBOM = async (bomID, partNumber, cost, quantity, description, products, quantityFlag, ignoreQuantityFlag) => {
		//https://cqzybp52y3.execute-api.us-west-2.amazonaws.com/v1/edit-bill-of-materials?QuantityFlag=0&BomID=173&PartNumber=000001&Cost=20.00&Description=Testing&Quantity=0
		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-bill-of-materials?QuantityFlag="+quantityFlag+"&BomID="+bomID+"&PartNumber="+partNumber+"&Cost="+cost+"&Description="+description+"&Quantity="+quantity+"&IgnoreQuantityFlag="+ignoreQuantityFlag;
		console.log("updateBOM url is:", url);

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Update bomData response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				await this.deleteAllProductItemsForBOM(bomID);

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForBOM(bomID, product);
				}

				// products.map((product, i) => {
				// 	this.addProductItemForBOM(bomID, product);
				// });

				toast.success("Part edited successfully.", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					closeButton: false
				});

				this.getBOMData(this.state.marketSelected);
			} else {
				//this.state.noDataForDevices = true;

				//console.log("Error creating BOM response: " + JSON.stringify(body));

				toast.error("Error editing part. Missing required fields.", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					closeButton: false
				});
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteBOM = async () => {

		console.log("Delete BOM: " + this.state.obj.PartNumber);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-bill-of-materials?BomID="+this.state.obj.bomID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete BOM response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle8();
				await this.deleteAllProductItemsForBOM(this.state.obj.bomID);
				this.getBOMData(this.state.marketSelected);

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getProductItemsForBOM = async (obj) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-product-items-for-bom?BomID="+obj.bomID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all productItemsForBOM data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productItemsForBOM: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteAllProductItemsForBOM = async (bomID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-product-items-for-bom?BomID="+bomID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all BOM data: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	addProductItemForBOM = async (bomID, productID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-product-item-for-bom?BomID="+bomID+"&ProductID="+productID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all BOM data: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	onChangeNewQuantity = (event) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);

		let id = event.target.id;
		let value = event.target.value;

		if (value.length>0) {
			value = parseInt(value);

			if (typeof value === 'number') {
			  //it's a number
				console.log("is Number");
				this.setState({ currentQuantityOfItem: value });
			} else {
				console.log("is not Number");
			}
		} else {
			this.setState({ currentQuantityOfItem: this.state.obj.Quantity });
		}

	}

	onChangeSubtractQuantity = (event) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);

		let id = event.target.id;
		let value = event.target.value;

		if (value.length>0) {
			value = parseInt(value);

			if (typeof value === 'number') {
			  //it's a number
				console.log("is Number");
				this.setState({ currentQuantityOfItem: this.state.obj.Quantity-value });
			} else {
				console.log("is not Number");
			}
		} else {
			this.setState({ currentQuantityOfItem: this.state.obj.Quantity });
		}

	}

	onChangeAddQuantity = (event) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);

		let id = event.target.id;
		let value = event.target.value;

		if (value.length>0) {
			value = parseInt(value);

			if (typeof value === 'number') {
			  //it's a number
				console.log("is Number");
				this.setState({ currentQuantityOfItem: this.state.obj.Quantity+value });
			} else {
				console.log("is not Number");
			}
		} else {
			this.setState({ currentQuantityOfItem: this.state.obj.Quantity });
		}

	}

	onChangeAdjustmentReason = (event) => {

		console.log("onChangeAdjustmentReason input id: " + event.target.id);
		console.log("onChangeAdjustmentReason input value: " + event.target.value);

		let id = event.target.id;
		let value = event.target.value;

		this.setState({reasonAdjustmentSelection: value, submitStatus: ''});

	}

	onChangeAuthorizedBy = (event) => {

		console.log("onChangeAuthorizedBy input id: " + event.target.id);
		console.log("onChangeAuthorizedBy input value: " + event.target.value);

		let id = event.target.id;
		let value = event.target.value;

		let user = this.state.availableUsers[value];
		console.log("AuthorizedBy user is:", user);

		this.setState({
      currentItem2: user.name,
			currentDataSetting2: value,
    });

		if (this.state.submitStatus) {
			this.setState({
				submitStatus: '',
			});
		}

	}

	costOrPartNumberChange = (event) => {

		console.log("costOrPartNumberChange input id: " + event.target.id);
		console.log("costOrPartNumberChange input value: " + event.target.value);

		let id = event.target.id;
		let value = event.target.value;

		this.setState({
			newPartNumber: document.getElementById("PartNumber").value,
			newCost: document.getElementById("Cost").value,
			newDescription: document.getElementById("Description").value,
		});

		if (this.state.submitStatus) {
			this.setState({
				submitStatus: '',
			});
		}

	}

	ignoreQuantityFlagChange = (event) => {

		console.log("ignoreQuantityFlagChange input id: " + event.target.id);
		console.log("ignoreQuantityFlagChange input value: " + event.target.value);
		console.log("ignoreQuantityFlagChange checked value: " + event.target.checked);

		let id = event.target.id;
		let value = event.target.value;
		let checked = event.target.checked;

		if (checked) {
			this.setState({
				disableQuantityFlag: true,
			});
		} else {
			this.setState({
				disableQuantityFlag: false,
			});
		}

	}

	onChangeAuthPassword = (event) => {

		// console.log("costOrPartNumberChange input id: " + event.target.id);
		// console.log("costOrPartNumberChange input value: " + event.target.value);
		//
		// let id = event.target.id;
		// let value = event.target.value;

		if (this.state.submitStatus) {
			this.setState({
				submitStatus: '',
			});
		}

	}

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));

		var productStr = row.Products.productName;

		if (row.Products.productsArray.length>0) {
			productStr = row.Products.allProducts;
		}

		// let rowData = {
		// 	PartNumber: row.PartNumber,
		// 	Cost: row.Cost,
		// 	CurrentQuantity: row.QuantityObject.currentQuantity,
		// 	Description: row.Description,
		// 	Products: productStr,
		// }

		//console.log("filterCaseInsensitive rowData: " + JSON.stringify(rowData));

		if (id == 'QuantityObject') {
			var quantityString = row[id].currentQuantity;

			// if (quantityString !== undefined) {
			//
			// 	if (String(quantityString.toString().toLowerCase()).includes(filter.value.toLowerCase())) {
			// 		exportListData.push(rowData);
			// 	}
			//
			// } else {
			// 	exportListData.push(rowData);
			// }

			return (
					quantityString !== undefined ?
							String(quantityString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (id == 'Products') {

			var productString = row[id].productName;

			if (row[id].productsArray.length>0) {
				productString = row[id].allProducts;
			}

			// if (productString !== undefined) {
			//
			// 	if (String(productString.toString().toLowerCase()).includes(filter.value.toLowerCase())) {
			// 		exportListData.push(rowData);
			// 	}
			//
			// } else {
			// 	exportListData.push(rowData);
			// }

			return (
					productString !== undefined ?
							String(productString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){

			// if (row[id] !== undefined) {
			//
			// 	if (String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())) {
			// 		exportListData.push(rowData);
			// 	}
			//
			// } else {
			// 	exportListData.push(rowData);
			// }

			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}

	exportList = async (displayedData) => {

		try {

			var data = [];

			var data = globalSortedData.map((prop, key) => {

				var productStr = prop.Products.productName;

				if (prop.Products.productsArray.length>0) {
					productStr = prop.Products.allProducts;
				}

				return {
					PartNumber: prop.PartNumber,
					Cost: prop.Cost,
					Quantity: prop.QuantityObject.currentQuantity,
					Description: prop.Description,
					Products: productStr,
				}

			});

			console.log("data: " + JSON.stringify(data, null, 4));
			console.log("data length: " + data.length);

			// var displayedDataFormatted = displayedData.map((prop, key) => {
			//
			// 	var productStr = prop.Products.productName;
			//
			// 	if (prop.Products.productsArray.length>0) {
			// 		productStr = prop.Products.allProducts;
			// 	}
			//
			// 	return {
			// 		PartNumber: prop.PartNumber,
			// 		Cost: prop.Cost,
			// 		Quantity: prop.QuantityObject.currentQuantity,
			// 		Description: prop.Description,
			// 		Products: productStr,
			// 	}
			// });
			//
			// if (globalFiltered.length>0) {
			//
			// 	data = displayedDataFormatted.filter((prop) => {
			//
			// 		var include = false;
			// 		var filteredTotal = globalFiltered.length;
			// 		var includeCount = 0;
			//
			// 		globalFiltered.forEach(filter => {
			//
			// 			if(filter.id === "PartNumber") {
			//
			// 				if (String(prop.PartNumber.toString().toLowerCase()).includes(filter.value.toLowerCase())) {
			// 					console.log("should include part: " + prop.PartNumber);
			// 					includeCount = includeCount+1;
			// 				}
			// 			}
			//
			// 			if(filter.id === "Cost") {
			//
			// 				if (String(prop.Cost.toString().toLowerCase()).includes(filter.value.toLowerCase())) {
			// 					includeCount = includeCount+1;
			// 				}
			// 			}
			//
			// 			if(filter.id === "QuantityObject") {
			//
			// 				if (String(prop.Quantity.toString().toLowerCase()).includes(filter.value.toLowerCase())) {
			// 					includeCount = includeCount+1;
			// 				}
			// 			}
			//
			// 			if(filter.id === "Description") {
			//
			// 				if (String(prop.Description.toString().toLowerCase()).includes(filter.value.toLowerCase())) {
			// 					includeCount = includeCount+1;
			// 				}
			// 			}
			//
			// 			if(filter.id === "Products") {
			//
			// 				if (String(prop.Products.toString().toLowerCase()).includes(filter.value.toLowerCase())) {
			// 					includeCount = includeCount+1;
			// 				}
			// 			}
			//
			// 		});
			//
			// 		if (filteredTotal==includeCount) {
			// 			return true;
			// 		}
			//
			// 	});
			//
			// 	console.log("displayedData data: " + JSON.stringify(data, null, 4));
			// 	console.log("displayedData data length: " + data.length);
			//
			// 	console.log("globalFiltered data: " + JSON.stringify(globalFiltered, null, 4));
			// 	console.log("globalFiltered data length: " + globalFiltered.length);
			// } else {
			//
			// 	data = displayedDataFormatted;
			//
			// 	console.log("displayedData data: " + JSON.stringify(data, null, 4));
			// 	console.log("displayedData data length: " + data.length);
			//
			// 	console.log("globalFiltered data: " + JSON.stringify(globalFiltered, null, 4));
			// 	console.log("globalFiltered data length: " + globalFiltered.length);
			// }


			const config = { delimiter: ',' };
			const csv2Export = Papa.unparse( data, config );

	    console.log("generateReport csv2Export: " + JSON.stringify(csv2Export));

	    var blob = new Blob([csv2Export]);
	    if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
	        window.navigator.msSaveBlob(blob, "Inventory_Report.csv");
	    else
	    {
	        var a = window.document.createElement("a");
	        a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
	        a.download = "Inventory_Report.csv";
	        document.body.appendChild(a);
	        a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
	        document.body.removeChild(a);
	    }

		}
		catch (err) {
			console.log(err);
		}

	};

  render() {

		const data = this.state.bomData.map((prop, key) => {

			var isChanged = false;

			//console.log("bomID: "+prop.id);
			//console.log("render quantitiesChanged: "+JSON.stringify(this.state.quantitiesChanged));
			let quantitiesChangedItem = this.state.quantitiesChanged.find(q => q.bomID === prop.id);
			//console.log("quantitiesChangedItem found: "+quantitiesChangedItem);

			var previousQuantity = prop.Quantity;

			if (quantitiesChangedItem) {
				//console.log("quantitiesChangedItem found: "+quantitiesChangedItem);
				previousQuantity = quantitiesChangedItem.previousQuantity;
				isChanged = true;
			}

			var quantityObject = {
				previousQuantity: previousQuantity,
				currentQuantity: prop.Quantity,
			};

			var productsArray = [];

			if (prop.allProducts) {
				productsArray = prop.allProducts.split(',');
			}

			return {
				id: key,
				bomID: prop.id,
				PartNumber: prop.PartNumber,
				Products: { productName:prop.productName, productsArray: productsArray, allProducts:prop.allProducts },
        Cost: prop.Cost,
				QuantityObject: quantityObject,
				Quantity: prop.Quantity,
				QuantityFlag: prop.QuantityFlag,
				IgnoreQFlag: prop.IgnoreQFlag,
				Description: prop.Description,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);

								let currentAvailableUser = this.state.availableUsers.find(u => u.email === this.state.currentUser.payload.email);

								this.setState({
									modal7: !this.state.modal7,
									obj: obj,
									currentQuantityOfItem: obj.Quantity,
									submitStatus: '',
									reasonAdjustmentSelection: 0,
									currentItem2: currentAvailableUser ? currentAvailableUser.name : this.state.availableUsers[0].name,
									currentDataSetting2: currentAvailableUser ? currentAvailableUser.id : 0,
									newPartNumber: obj.PartNumber,
									newCost: obj.Cost,
									newDescription: obj.Description,
									disableQuantityFlag: obj.IgnoreQFlag==1 ? true : false
								});

								this.getProductItemsForBOM(obj)
								//this.showVerification(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal8: !this.state.modal8,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		const data2 = this.state.ausBomData.map((prop, key) => {

			var isChanged = false;

			//console.log("bomID: "+prop.id);
			//console.log("render quantitiesChanged: "+JSON.stringify(this.state.quantitiesChanged));
			let quantitiesChangedItem = this.state.quantitiesChanged.find(q => q.bomID === prop.id);
			//console.log("quantitiesChangedItem found: "+quantitiesChangedItem);

			var previousQuantity = prop.Quantity;

			if (quantitiesChangedItem) {
				//console.log("quantitiesChangedItem found: "+quantitiesChangedItem);
				previousQuantity = quantitiesChangedItem.previousQuantity;
				isChanged = true;
			}

			var quantityObject = {
				previousQuantity: previousQuantity,
				currentQuantity: prop.Quantity,
			};

			var productsArray = [];

			if (prop.allProducts) {
				productsArray = prop.allProducts.split(',');
			}

			return {
				id: key,
				bomID: prop.id,
				PartNumber: prop.PartNumber,
				Products: { productName:prop.productName, productsArray: productsArray, allProducts:prop.allProducts },
        Cost: prop.Cost,
				QuantityObject: quantityObject,
				Quantity: prop.Quantity,
				QuantityFlag: prop.QuantityFlag,
				IgnoreQFlag: prop.IgnoreQFlag,
				Description: prop.Description,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);

								let currentAvailableUser = this.state.availableUsers.find(u => u.email === this.state.currentUser.payload.email);

								this.setState({
									modal7: !this.state.modal7,
									obj: obj,
									currentQuantityOfItem: obj.Quantity,
									submitStatus: '',
									reasonAdjustmentSelection: 0,
									currentItem2: currentAvailableUser ? currentAvailableUser.name : this.state.availableUsers[0].name,
									currentDataSetting2: currentAvailableUser ? currentAvailableUser.id : 0,
									newPartNumber: obj.PartNumber,
									newCost: obj.Cost,
									newDescription: obj.Description,
									disableQuantityFlag: obj.IgnoreQFlag==1 ? true : false,
								});

								this.getProductItemsForBOM(obj)
								//this.showVerification(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal8: !this.state.modal8,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		const data3 = this.state.europeBomData.map((prop, key) => {

			var isChanged = false;

			//console.log("bomID: "+prop.id);
			//console.log("render quantitiesChanged: "+JSON.stringify(this.state.quantitiesChanged));
			let quantitiesChangedItem = this.state.quantitiesChanged.find(q => q.bomID === prop.id);
			//console.log("quantitiesChangedItem found: "+quantitiesChangedItem);

			var previousQuantity = prop.Quantity;

			if (quantitiesChangedItem) {
				//console.log("quantitiesChangedItem found: "+quantitiesChangedItem);
				previousQuantity = quantitiesChangedItem.previousQuantity;
				isChanged = true;
			}

			var quantityObject = {
				previousQuantity: previousQuantity,
				currentQuantity: prop.Quantity,
			};

			var productsArray = [];

			if (prop.allProducts) {
				productsArray = prop.allProducts.split(',');
			}

			return {
				id: key,
				bomID: prop.id,
				PartNumber: prop.PartNumber,
				Products: { productName:prop.productName, productsArray: productsArray, allProducts:prop.allProducts },
        Cost: prop.Cost,
				QuantityObject: quantityObject,
				Quantity: prop.Quantity,
				QuantityFlag: prop.QuantityFlag,
				IgnoreQFlag: prop.IgnoreQFlag,
				Description: prop.Description,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data3.find(o => o.id === key);
                console.log('button obj: ' + obj);

								let currentAvailableUser = this.state.availableUsers.find(u => u.email === this.state.currentUser.payload.email);

								this.setState({
									modal7: !this.state.modal7,
									obj: obj,
									currentQuantityOfItem: obj.Quantity,
									submitStatus: '',
									reasonAdjustmentSelection: 0,
									currentItem2: currentAvailableUser ? currentAvailableUser.name : this.state.availableUsers[0].name,
									currentDataSetting2: currentAvailableUser ? currentAvailableUser.id : 0,
									newPartNumber: obj.PartNumber,
									newCost: obj.Cost,
									newDescription: obj.Description,
									disableQuantityFlag: obj.IgnoreQFlag==1 ? true : false,
								});

								this.getProductItemsForBOM(obj)
								//this.showVerification(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data3.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal8: !this.state.modal8,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		const data4 = this.state.brazilBomData.map((prop, key) => {

			var isChanged = false;

			//console.log("bomID: "+prop.id);
			//console.log("render quantitiesChanged: "+JSON.stringify(this.state.quantitiesChanged));
			let quantitiesChangedItem = this.state.quantitiesChanged.find(q => q.bomID === prop.id);
			//console.log("quantitiesChangedItem found: "+quantitiesChangedItem);

			var previousQuantity = prop.Quantity;

			if (quantitiesChangedItem) {
				//console.log("quantitiesChangedItem found: "+quantitiesChangedItem);
				previousQuantity = quantitiesChangedItem.previousQuantity;
				isChanged = true;
			}

			var quantityObject = {
				previousQuantity: previousQuantity,
				currentQuantity: prop.Quantity,
			};

			var productsArray = [];

			if (prop.allProducts) {
				productsArray = prop.allProducts.split(',');
			}

			return {
				id: key,
				bomID: prop.id,
				PartNumber: prop.PartNumber,
				Products: { productName:prop.productName, productsArray: productsArray, allProducts:prop.allProducts },
        Cost: prop.Cost,
				QuantityObject: quantityObject,
				Quantity: prop.Quantity,
				QuantityFlag: prop.QuantityFlag,
				IgnoreQFlag: prop.IgnoreQFlag,
				Description: prop.Description,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data4.find(o => o.id === key);
                console.log('button obj: ' + obj);

								let currentAvailableUser = this.state.availableUsers.find(u => u.email === this.state.currentUser.payload.email);

								this.setState({
									modal7: !this.state.modal7,
									obj: obj,
									currentQuantityOfItem: obj.Quantity,
									submitStatus: '',
									reasonAdjustmentSelection: 0,
									currentItem2: currentAvailableUser ? currentAvailableUser.name : this.state.availableUsers[0].name,
									currentDataSetting2: currentAvailableUser ? currentAvailableUser.id : 0,
									newPartNumber: obj.PartNumber,
									newCost: obj.Cost,
									newDescription: obj.Description,
									disableQuantityFlag: obj.IgnoreQFlag==1 ? true : false,
								});

								this.getProductItemsForBOM(obj)
								//this.showVerification(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data4.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal8: !this.state.modal8,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		const data6 = this.state.taiwanBomData.map((prop, key) => {

			var isChanged = false;

			//console.log("bomID: "+prop.id);
			//console.log("render quantitiesChanged: "+JSON.stringify(this.state.quantitiesChanged));
			let quantitiesChangedItem = this.state.quantitiesChanged.find(q => q.bomID === prop.id);
			//console.log("quantitiesChangedItem found: "+quantitiesChangedItem);

			var previousQuantity = prop.Quantity;

			if (quantitiesChangedItem) {
				//console.log("quantitiesChangedItem found: "+quantitiesChangedItem);
				previousQuantity = quantitiesChangedItem.previousQuantity;
				isChanged = true;
			}

			var quantityObject = {
				previousQuantity: previousQuantity,
				currentQuantity: prop.Quantity,
			};

			var productsArray = [];

			if (prop.allProducts) {
				productsArray = prop.allProducts.split(',');
			}

			return {
				id: key,
				bomID: prop.id,
				PartNumber: prop.PartNumber,
				Products: { productName:prop.productName, productsArray: productsArray, allProducts:prop.allProducts },
        Cost: prop.Cost,
				QuantityObject: quantityObject,
				Quantity: prop.Quantity,
				QuantityFlag: prop.QuantityFlag,
				IgnoreQFlag: prop.IgnoreQFlag,
				Description: prop.Description,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data6.find(o => o.id === key);
                console.log('button obj: ' + obj);

								let currentAvailableUser = this.state.availableUsers.find(u => u.email === this.state.currentUser.payload.email);

								this.setState({
									modal7: !this.state.modal7,
									obj: obj,
									currentQuantityOfItem: obj.Quantity,
									submitStatus: '',
									reasonAdjustmentSelection: 0,
									currentItem2: currentAvailableUser ? currentAvailableUser.name : this.state.availableUsers[0].name,
									currentDataSetting2: currentAvailableUser ? currentAvailableUser.id : 0,
									newPartNumber: obj.PartNumber,
									newCost: obj.Cost,
									newDescription: obj.Description,
									disableQuantityFlag: obj.IgnoreQFlag==1 ? true : false,
								});

								this.getProductItemsForBOM(obj)
								//this.showVerification(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data6.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal8: !this.state.modal8,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		const data7 = this.state.colombiaBomData.map((prop, key) => {

			var isChanged = false;

			//console.log("bomID: "+prop.id);
			//console.log("render quantitiesChanged: "+JSON.stringify(this.state.quantitiesChanged));
			let quantitiesChangedItem = this.state.quantitiesChanged.find(q => q.bomID === prop.id);
			//console.log("quantitiesChangedItem found: "+quantitiesChangedItem);

			var previousQuantity = prop.Quantity;

			if (quantitiesChangedItem) {
				//console.log("quantitiesChangedItem found: "+quantitiesChangedItem);
				previousQuantity = quantitiesChangedItem.previousQuantity;
				isChanged = true;
			}

			var quantityObject = {
				previousQuantity: previousQuantity,
				currentQuantity: prop.Quantity,
			};

			var productsArray = [];

			if (prop.allProducts) {
				productsArray = prop.allProducts.split(',');
			}

			return {
				id: key,
				bomID: prop.id,
				PartNumber: prop.PartNumber,
				Products: { productName:prop.productName, productsArray: productsArray, allProducts:prop.allProducts },
        Cost: prop.Cost,
				QuantityObject: quantityObject,
				Quantity: prop.Quantity,
				QuantityFlag: prop.QuantityFlag,
				IgnoreQFlag: prop.IgnoreQFlag,
				Description: prop.Description,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data7.find(o => o.id === key);
                console.log('button obj: ' + obj);

								let currentAvailableUser = this.state.availableUsers.find(u => u.email === this.state.currentUser.payload.email);

								this.setState({
									modal7: !this.state.modal7,
									obj: obj,
									currentQuantityOfItem: obj.Quantity,
									submitStatus: '',
									reasonAdjustmentSelection: 0,
									currentItem2: currentAvailableUser ? currentAvailableUser.name : this.state.availableUsers[0].name,
									currentDataSetting2: currentAvailableUser ? currentAvailableUser.id : 0,
									newPartNumber: obj.PartNumber,
									newCost: obj.Cost,
									newDescription: obj.Description,
									disableQuantityFlag: obj.IgnoreQFlag==1 ? true : false,
								});

								this.getProductItemsForBOM(obj)
								//this.showVerification(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data7.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal8: !this.state.modal8,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		const data5 = this.state.assetData.map((prop, key) => {

			// var isChanged = false;
			//
			// let quantitiesChangedItem = this.state.quantitiesChanged.find(q => q.bomID === prop.id);
			//
			// var previousQuantity = prop.Quantity;
			//
			// if (quantitiesChangedItem) {
			// 	previousQuantity = quantitiesChangedItem.previousQuantity;
			// 	isChanged = true;
			// }
			//
			// var quantityObject = {
			// 	previousQuantity: previousQuantity,
			// 	currentQuantity: prop.Quantity,
			// };

			let market = this.state.marketsData.find(m => m.id === prop.MarketID);

			return {
				id: key,
				assetID: prop.id,
				PartNumber: prop.PartNumber,
				SerialNumber: prop.SerialNumber,
				Description: prop.Description,
        Cost: prop.Cost,
				Quantity: prop.Quantity,
				MarketID: prop.MarketID,
				MarketName: market.Name,
				CalibrationDate: prop.CalibrationDate,
				CalibrationPeriod: prop.CalibrationPeriod,
				DeliveryDate: prop.DeliveryDate,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data5.find(o => o.id === key);
                console.log('button obj: ' + obj);

								//let currentAvailableUser = this.state.availableUsers.find(u => u.email === this.state.currentUser.payload.email);

								this.setState({
									modal2: !this.state.modal2,
									obj: obj,
									submitStatus: '',
								});

								//this.getProductItemsForBOM(obj)
								//this.showVerification(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data5.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal3: !this.state.modal3,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		var displayedData = [];

		if (this.state.marketSelected==0) {
			displayedData = data;
		} else if (this.state.marketSelected==1) {
			displayedData = data3;
		} else if (this.state.marketSelected==2) {
			displayedData = data2;
		} else if (this.state.marketSelected==3) {
			displayedData = data4;
		} else if (this.state.marketSelected==4) {
			displayedData = data6;
		} else if (this.state.marketSelected==5) {
			displayedData = data7;
		} else if (this.state.marketSelected==99) {
			displayedData = data5;
		}

		return (
			<Fragment>
				<Row>
					<Col md="4">
					</Col>
					<Col md="4">

					</Col>
					<Col md="4">
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<Card className="card-hover">
							<CardBody>
								<Row className="mb-1">
									<Col md="2">
										{this.state.marketSelected==0 &&
											<Fade in="true">
											<h3 style={{color:'#737782'}}>US Inventory Management</h3>
											</Fade>
										}
										{this.state.marketSelected==1 &&
											<Fade in="true">
											<h3 style={{color:'#737782'}}>Europe Inventory Management</h3>
											</Fade>
										}
										{this.state.marketSelected==2 &&
											<Fade in="true">
											<h3 style={{color:'#737782'}}>Australia Inventory Management</h3>
											</Fade>
										}
										{this.state.marketSelected==3 &&
											<Fade in="true">
											<h3 style={{color:'#737782'}}>Brazil Inventory Management</h3>
											</Fade>
										}
										{this.state.marketSelected==4 &&
											<Fade in="true">
											<h3 style={{color:'#737782'}}>Taiwan Inventory Management</h3>
											</Fade>
										}
										{this.state.marketSelected==5 &&
											<Fade in="true">
											<h3 style={{color:'#737782'}}>Colombia Inventory Management</h3>
											</Fade>
										}
										{this.state.marketSelected==99 &&
											<Fade in="true">
											<h3 style={{color:'#737782'}}>All Asset Management</h3>
											</Fade>
										}
									</Col>
									<Col md="8">
										<div className="mt-1 mb-1">
											<ButtonGroup style={{ width: '100%' }}>
												<Button
													style={{width: '100%'}}
													color="btngray"
													onClick={() => this.onRadioBtnClick(0)}
													active={this.state.marketSelected === 0}
												>
													US Inventory
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(1)}
													active={this.state.marketSelected === 1}
												>
													Europe Inventory
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(2)}
													active={this.state.marketSelected === 2}
												>
													Australia Inventory
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(3)}
													active={this.state.marketSelected === 3}
												>
													Brazil Inventory
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(4)}
													active={this.state.marketSelected === 4}
												>
													Taiwan Inventory
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(5)}
													active={this.state.marketSelected === 5}
												>
													Colombia Inventory
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(99)}
													active={this.state.marketSelected === 99}
												>
													All Assets
												</Button>
											</ButtonGroup>
										</div>
									</Col>
									<Col md="2" className="text-right">
										<div>
											{
												// <ButtonDropdown isOpen={this.state.isOpen2} toggle={this.toggleDropDown2}>
												// 	<DropdownToggle color="dark" caret>
												// 		{this.state.currentItem2}
												// 	</DropdownToggle>
												// 	<DropdownMenu right>
												// 		{this.state.availableUsers.map((user, i) => (
												// 			<DropdownItem onClick={() => this.selectDropDownItem2(i, user.name)}>{user.name}</DropdownItem>
												// 		))}
												// 	</DropdownMenu>
												// </ButtonDropdown>
											}
											{this.state.marketSelected!=99 ? (
												<Fragment>
												{
													// <ButtonDropdown className="ml-3" isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
													// 	<DropdownToggle color="dark" caret>
													// 		{this.state.currentItem}
													// 	</DropdownToggle>
													// 	<DropdownMenu right>
													// 		<DropdownItem onClick={() => this.selectDropDownItem(0, 'ALL')}>ALL</DropdownItem>
													// 		{this.state.productsData.map((product, i) => (
													// 			<DropdownItem onClick={() => this.selectDropDownItem(product.id, product.Name)}>{product.Name}</DropdownItem>
													// 		))}
													// 	</DropdownMenu>
													// </ButtonDropdown>
												}
												<Button style={{ width: "130px" }} className="float-right ml-3" color="yellow" onClick={this.toggle11}>Create New Part</Button>
												<Button style={{ width: "130px" }} className="float-right ml-3 mt-2" color="yellow" onClick={() => {this.exportList(displayedData)}}>Export List</Button>
												</Fragment>
											) : (
												<Button className="float-right ml-3" color="yellow" onClick={this.toggle1}>Create New Asset</Button>
											)}

										</div>
									</Col>
								</Row>
								{this.state.marketSelected!=99 &&
									<Row className="mb-3">
										<Col md="4">
										</Col>
										<Col md="4">
											<div className="">
												<ButtonGroup style={{ width: '100%' }}>
													<Button
														style={{width: '100%'}}
														color="btngray"
														onClick={() => this.onRadioBtnClick2(0)}
														active={this.state.currentDataSetting === 0}
													>
														ALL
													</Button>
													{this.state.productsData.map((product, i) => (
														<Button
															className="ml-1"
															style={{width: '100%'}}
															color="btngray"
															onClick={() => this.onRadioBtnClick2(product.id)}
															active={this.state.currentDataSetting === product.id}
														>
															{product.Name}
														</Button>
													))}
												</ButtonGroup>
											</div>
										</Col>
										<Col md="4">
										</Col>
									</Row>
								}
								{this.state.marketSelected==0 &&
									<Fade in="true">
									<ReactTable
										ref={(r) => {
									    this.selectTable1 = r;
									  }}
										style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
										columns={[
											{
												Header: "Part Number",
												accessor: "PartNumber",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Cost",
												accessor: "Cost",
												minWidth: 8,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Quantity",
												accessor: "QuantityObject",
												minWidth: 8,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<Fragment>
														{row.value.previousQuantity == row.value.currentQuantity ? (
															<div style={{ textAlign: "center" }}>{row.value.currentQuantity}</div>
														) : (
															<div style={{ textAlign: "center" }}><b>{row.value.previousQuantity} / <span style={{ color: "#FFC108"}}>{row.value.currentQuantity}</span></b></div>
														)}
														</Fragment>
													)
											},
											{
												Header: "Description",
												accessor: "Description",
												minWidth: 40,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Product",
												accessor: "Products",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<Fragment>
														{row.value.productsArray.length>0 ? (
															<div style={{ textAlign: "center" }}>
																{row.value.productsArray.map((product, i) => (
																	<Fragment>
																	<Col xs="12">
																		<span>{product}</span>
																	</Col>
																	</Fragment>
																	))
																}
															</div>
														) : (
															<div style={{ textAlign: "center" }}>{row.value.productName}</div>
														)}
														</Fragment>
													)
											},
											{
												Header: "Actions",
												accessor: "actions",
												sortable: false,
												minWidth: 15,
												filterable: false
											}
										]}
										defaultPageSize={14}
										sortable={true}
										showPaginationBottom={true}
										className="-striped -highlight"
										data={data}
										filterable
										filterAll={true}
										defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
										getTrGroupProps={(state, rowInfo, column, instance) => {
														if (rowInfo !== undefined) {
																return {
																		onClick: (e, handleOriginal) => {
																			console.log('It was in this row:', rowInfo)
																			console.log('with ID:', rowInfo.original.categoryID);

																			//this.goToRepairLog(rowInfo.original.repairId);
																		},
																		style: {
																				cursor: 'pointer'
																		}
																}
														}}
												}
									>
									{(state, makeTable, instance) => {
				            let recordsInfoText = "";

				            const { filtered, pageRows, pageSize, sortedData, page } = state;

				            if (sortedData && sortedData.length > 0) {
				              let isFiltered = filtered.length > 0;

				              let totalRecords = sortedData.length;

											globalSortedData = sortedData;

											globalFiltered = filtered;

				              let recordsCountFrom = page * pageSize + 1;

				              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

				              if (isFiltered)
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
				              else
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
				            } else recordsInfoText = "No records";

				            return (
				              <div className="main-grid mt-2">
												{makeTable()}
												<Row className="mt-2 text-center">
													<Col md="12">
														<Label style={{fontSize:'15px'}} for=""><b>{recordsInfoText}</b></Label>
													</Col>
												</Row>
				              </div>
				            );
				          }}
				        	</ReactTable>
									</Fade>
								}
								{this.state.marketSelected==1 &&
									<Fade in="true">
									<ReactTable
										style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
										columns={[
											{
												Header: "Part Number",
												accessor: "PartNumber",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Cost",
												accessor: "Cost",
												minWidth: 8,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Quantity",
												accessor: "QuantityObject",
												minWidth: 8,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<Fragment>
														{row.value.previousQuantity == row.value.currentQuantity ? (
															<div style={{ textAlign: "center" }}>{row.value.currentQuantity}</div>
														) : (
															<div style={{ textAlign: "center" }}><b>{row.value.previousQuantity} / <span style={{ color: "#FFC108"}}>{row.value.currentQuantity}</span></b></div>
														)}
														</Fragment>
													)
											},
											{
												Header: "Description",
												accessor: "Description",
												minWidth: 40,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Product",
												accessor: "Products",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<Fragment>
														{row.value.productsArray.length>0 ? (
															<div style={{ textAlign: "center" }}>
																{row.value.productsArray.map((product, i) => (
																	<Fragment>
																	<Col xs="12">
																		<span>{product}</span>
																	</Col>
																	</Fragment>
																	))
																}
															</div>
														) : (
															<div style={{ textAlign: "center" }}>{row.value.productName}</div>
														)}
														</Fragment>
													)
											},
											{
												Header: "Actions",
												accessor: "actions",
												sortable: false,
												minWidth: 15,
												filterable: false
											}
										]}
										defaultPageSize={14}
										sortable={true}
										showPaginationBottom={true}
										className="-striped -highlight"
										data={data3}
										filterable
										filterAll={true}
										defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
										getTrGroupProps={(state, rowInfo, column, instance) => {
														if (rowInfo !== undefined) {
																return {
																		onClick: (e, handleOriginal) => {
																			console.log('It was in this row:', rowInfo)
																			console.log('with ID:', rowInfo.original.categoryID);

																			//this.goToRepairLog(rowInfo.original.repairId);
																		},
																		style: {
																				cursor: 'pointer'
																		}
																}
														}}
												}
									>
									{(state, makeTable, instance) => {
				            let recordsInfoText = "";

				            const { filtered, pageRows, pageSize, sortedData, page } = state;

				            if (sortedData && sortedData.length > 0) {
				              let isFiltered = filtered.length > 0;

				              let totalRecords = sortedData.length;

											globalSortedData = sortedData;

											globalFiltered = filtered;

				              let recordsCountFrom = page * pageSize + 1;

				              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

				              if (isFiltered)
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
				              else
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
				            } else recordsInfoText = "No records";

				            return (
				              <div className="main-grid mt-2">
												{makeTable()}
												<Row className="mt-2 text-center">
													<Col md="12">
														<Label style={{fontSize:'15px'}} for=""><b>{recordsInfoText}</b></Label>
													</Col>
												</Row>
				              </div>
				            );
				          }}
				        	</ReactTable>
									</Fade>
								}
								{this.state.marketSelected==2 &&
									<Fade in="true">
									<ReactTable
										style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
										columns={[
											{
												Header: "Part Number",
												accessor: "PartNumber",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Cost",
												accessor: "Cost",
												minWidth: 8,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Quantity",
												accessor: "QuantityObject",
												minWidth: 8,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<Fragment>
														{row.value.previousQuantity == row.value.currentQuantity ? (
															<div style={{ textAlign: "center" }}>{row.value.currentQuantity}</div>
														) : (
															<div style={{ textAlign: "center" }}><b>{row.value.previousQuantity} / <span style={{ color: "#FFC108"}}>{row.value.currentQuantity}</span></b></div>
														)}
														</Fragment>
													)
											},
											{
												Header: "Description",
												accessor: "Description",
												minWidth: 40,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Product",
												accessor: "Products",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<Fragment>
														{row.value.productsArray.length>0 ? (
															<div style={{ textAlign: "center" }}>
																{row.value.productsArray.map((product, i) => (
																	<Fragment>
																	<Col xs="12">
																		<span>{product}</span>
																	</Col>
																	</Fragment>
																	))
																}
															</div>
														) : (
															<div style={{ textAlign: "center" }}>{row.value.productName}</div>
														)}
														</Fragment>
													)
											},
											{
												Header: "Actions",
												accessor: "actions",
												sortable: false,
												minWidth: 15,
												filterable: false
											}
										]}
										defaultPageSize={14}
										sortable={true}
										showPaginationBottom={true}
										className="-striped -highlight"
										data={data2}
										filterable
										filterAll={true}
										defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
										getTrGroupProps={(state, rowInfo, column, instance) => {
														if (rowInfo !== undefined) {
																return {
																		onClick: (e, handleOriginal) => {
																			console.log('It was in this row:', rowInfo)
																			console.log('with ID:', rowInfo.original.categoryID);

																			//this.goToRepairLog(rowInfo.original.repairId);
																		},
																		style: {
																				cursor: 'pointer'
																		}
																}
														}}
												}
									>
									{(state, makeTable, instance) => {
				            let recordsInfoText = "";

				            const { filtered, pageRows, pageSize, sortedData, page } = state;

				            if (sortedData && sortedData.length > 0) {
				              let isFiltered = filtered.length > 0;

				              let totalRecords = sortedData.length;

											globalSortedData = sortedData;

											globalFiltered = filtered;

				              let recordsCountFrom = page * pageSize + 1;

				              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

				              if (isFiltered)
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
				              else
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
				            } else recordsInfoText = "No records";

				            return (
				              <div className="main-grid mt-2">
												{makeTable()}
												<Row className="mt-2 text-center">
													<Col md="12">
														<Label style={{fontSize:'15px'}} for=""><b>{recordsInfoText}</b></Label>
													</Col>
												</Row>
				              </div>
				            );
				          }}
				        	</ReactTable>
									</Fade>
								}
								{this.state.marketSelected==3 &&
									<Fade in="true">
									<ReactTable
										style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
										columns={[
											{
												Header: "Part Number",
												accessor: "PartNumber",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Cost",
												accessor: "Cost",
												minWidth: 8,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Quantity",
												accessor: "QuantityObject",
												minWidth: 8,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<Fragment>
														{row.value.previousQuantity == row.value.currentQuantity ? (
															<div style={{ textAlign: "center" }}>{row.value.currentQuantity}</div>
														) : (
															<div style={{ textAlign: "center" }}><b>{row.value.previousQuantity} / <span style={{ color: "#FFC108"}}>{row.value.currentQuantity}</span></b></div>
														)}
														</Fragment>
													)
											},
											{
												Header: "Description",
												accessor: "Description",
												minWidth: 40,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Product",
												accessor: "Products",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<Fragment>
														{row.value.productsArray.length>0 ? (
															<div style={{ textAlign: "center" }}>
																{row.value.productsArray.map((product, i) => (
																	<Fragment>
																	<Col xs="12">
																		<span>{product}</span>
																	</Col>
																	</Fragment>
																	))
																}
															</div>
														) : (
															<div style={{ textAlign: "center" }}>{row.value.productName}</div>
														)}
														</Fragment>
													)
											},
											{
												Header: "Actions",
												accessor: "actions",
												sortable: false,
												minWidth: 15,
												filterable: false
											}
										]}
										defaultPageSize={14}
										sortable={true}
										showPaginationBottom={true}
										className="-striped -highlight"
										data={data4}
										filterable
										filterAll={true}
										defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
										getTrGroupProps={(state, rowInfo, column, instance) => {
														if (rowInfo !== undefined) {
																return {
																		onClick: (e, handleOriginal) => {
																			console.log('It was in this row:', rowInfo)
																			console.log('with ID:', rowInfo.original.categoryID);

																			//this.goToRepairLog(rowInfo.original.repairId);
																		},
																		style: {
																				cursor: 'pointer'
																		}
																}
														}}
												}
									>
									{(state, makeTable, instance) => {
				            let recordsInfoText = "";

				            const { filtered, pageRows, pageSize, sortedData, page } = state;

				            if (sortedData && sortedData.length > 0) {
				              let isFiltered = filtered.length > 0;

				              let totalRecords = sortedData.length;

											globalSortedData = sortedData;

											globalFiltered = filtered;

				              let recordsCountFrom = page * pageSize + 1;

				              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

				              if (isFiltered)
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
				              else
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
				            } else recordsInfoText = "No records";

				            return (
				              <div className="main-grid mt-2">
												{makeTable()}
												<Row className="mt-2 text-center">
													<Col md="12">
														<Label style={{fontSize:'15px'}} for=""><b>{recordsInfoText}</b></Label>
													</Col>
												</Row>
				              </div>
				            );
				          }}
				        	</ReactTable>
									</Fade>
								}
								{this.state.marketSelected==4 &&
									<Fade in="true">
									<ReactTable
										style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
										columns={[
											{
												Header: "Part Number",
												accessor: "PartNumber",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Cost",
												accessor: "Cost",
												minWidth: 8,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Quantity",
												accessor: "QuantityObject",
												minWidth: 8,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<Fragment>
														{row.value.previousQuantity == row.value.currentQuantity ? (
															<div style={{ textAlign: "center" }}>{row.value.currentQuantity}</div>
														) : (
															<div style={{ textAlign: "center" }}><b>{row.value.previousQuantity} / <span style={{ color: "#FFC108"}}>{row.value.currentQuantity}</span></b></div>
														)}
														</Fragment>
													)
											},
											{
												Header: "Description",
												accessor: "Description",
												minWidth: 40,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Product",
												accessor: "Products",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<Fragment>
														{row.value.productsArray.length>0 ? (
															<div style={{ textAlign: "center" }}>
																{row.value.productsArray.map((product, i) => (
																	<Fragment>
																	<Col xs="12">
																		<span>{product}</span>
																	</Col>
																	</Fragment>
																	))
																}
															</div>
														) : (
															<div style={{ textAlign: "center" }}>{row.value.productName}</div>
														)}
														</Fragment>
													)
											},
											{
												Header: "Actions",
												accessor: "actions",
												sortable: false,
												minWidth: 15,
												filterable: false
											}
										]}
										defaultPageSize={14}
										sortable={true}
										showPaginationBottom={true}
										className="-striped -highlight"
										data={data6}
										filterable
										filterAll={true}
										defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
										getTrGroupProps={(state, rowInfo, column, instance) => {
														if (rowInfo !== undefined) {
																return {
																		onClick: (e, handleOriginal) => {
																			console.log('It was in this row:', rowInfo)
																			console.log('with ID:', rowInfo.original.categoryID);

																			//this.goToRepairLog(rowInfo.original.repairId);
																		},
																		style: {
																				cursor: 'pointer'
																		}
																}
														}}
												}
									>
									{(state, makeTable, instance) => {
				            let recordsInfoText = "";

				            const { filtered, pageRows, pageSize, sortedData, page } = state;

				            if (sortedData && sortedData.length > 0) {
				              let isFiltered = filtered.length > 0;

				              let totalRecords = sortedData.length;

											globalSortedData = sortedData;

											globalFiltered = filtered;

				              let recordsCountFrom = page * pageSize + 1;

				              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

				              if (isFiltered)
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
				              else
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
				            } else recordsInfoText = "No records";

				            return (
				              <div className="main-grid mt-2">
												{makeTable()}
												<Row className="mt-2 text-center">
													<Col md="12">
														<Label style={{fontSize:'15px'}} for=""><b>{recordsInfoText}</b></Label>
													</Col>
												</Row>
				              </div>
				            );
				          }}
				        	</ReactTable>
									</Fade>
								}
								{this.state.marketSelected==5 &&
									<Fade in="true">
									<ReactTable
										style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
										columns={[
											{
												Header: "Part Number",
												accessor: "PartNumber",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Cost",
												accessor: "Cost",
												minWidth: 8,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Quantity",
												accessor: "QuantityObject",
												minWidth: 8,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<Fragment>
														{row.value.previousQuantity == row.value.currentQuantity ? (
															<div style={{ textAlign: "center" }}>{row.value.currentQuantity}</div>
														) : (
															<div style={{ textAlign: "center" }}><b>{row.value.previousQuantity} / <span style={{ color: "#FFC108"}}>{row.value.currentQuantity}</span></b></div>
														)}
														</Fragment>
													)
											},
											{
												Header: "Description",
												accessor: "Description",
												minWidth: 40,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Product",
												accessor: "Products",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<Fragment>
														{row.value.productsArray.length>0 ? (
															<div style={{ textAlign: "center" }}>
																{row.value.productsArray.map((product, i) => (
																	<Fragment>
																	<Col xs="12">
																		<span>{product}</span>
																	</Col>
																	</Fragment>
																	))
																}
															</div>
														) : (
															<div style={{ textAlign: "center" }}>{row.value.productName}</div>
														)}
														</Fragment>
													)
											},
											{
												Header: "Actions",
												accessor: "actions",
												sortable: false,
												minWidth: 15,
												filterable: false
											}
										]}
										defaultPageSize={14}
										sortable={true}
										showPaginationBottom={true}
										className="-striped -highlight"
										data={data7}
										filterable
										filterAll={true}
										defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
										getTrGroupProps={(state, rowInfo, column, instance) => {
														if (rowInfo !== undefined) {
																return {
																		onClick: (e, handleOriginal) => {
																			console.log('It was in this row:', rowInfo)
																			console.log('with ID:', rowInfo.original.categoryID);

																			//this.goToRepairLog(rowInfo.original.repairId);
																		},
																		style: {
																				cursor: 'pointer'
																		}
																}
														}}
												}
									>
									{(state, makeTable, instance) => {
				            let recordsInfoText = "";

				            const { filtered, pageRows, pageSize, sortedData, page } = state;

				            if (sortedData && sortedData.length > 0) {
				              let isFiltered = filtered.length > 0;

				              let totalRecords = sortedData.length;

											globalSortedData = sortedData;

											globalFiltered = filtered;

				              let recordsCountFrom = page * pageSize + 1;

				              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

				              if (isFiltered)
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
				              else
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
				            } else recordsInfoText = "No records";

				            return (
				              <div className="main-grid mt-2">
												{makeTable()}
												<Row className="mt-2 text-center">
													<Col md="12">
														<Label style={{fontSize:'15px'}} for=""><b>{recordsInfoText}</b></Label>
													</Col>
												</Row>
				              </div>
				            );
				          }}
				        	</ReactTable>
									</Fade>
								}
								{this.state.marketSelected==99 &&
									<Fade in="true">
									<ReactTable
										style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
										columns={[
											{
												Header: "Part Number",
												accessor: "PartNumber",
												minWidth: 12,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Serial Number",
												accessor: "SerialNumber",
												minWidth: 12,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Description",
												accessor: "Description",
												minWidth: 40,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Cost",
												accessor: "Cost",
												minWidth: 10,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Quantity",
												accessor: "Quantity",
												minWidth: 10,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Market",
												accessor: "MarketName",
												minWidth: 8,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<Fragment>
															<div style={{ textAlign: "center" }}>{row.value}</div>
														</Fragment>
													)
											},
											{
												Header: "Calibration Date",
												accessor: "CalibrationDate",
												minWidth: 12,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{Moment(row.value).isValid() ? Moment(row.value).utc(false).format('MM/DD/YYYY') : "N/A"}</div>
													)
											},
											{
												Header: "Calibration Period",
												accessor: "CalibrationPeriod",
												minWidth: 12,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value} months</div>
													)
											},
											{
												Header: "Delivery Date",
												accessor: "DeliveryDate",
												minWidth: 12,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{(row.value && Moment(row.value).isValid()) ? Moment(row.value).utc(false).format('MM/DD/YYYY') : "N/A"}</div>
													)
											},
											{
												Header: "Actions",
												accessor: "actions",
												sortable: false,
												minWidth: 15,
												filterable: false
											}
										]}
										defaultPageSize={14}
										sortable={true}
										showPaginationBottom={true}
										className="-striped -highlight"
										data={data5}
										filterable
										filterAll={true}
										defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
										getTrGroupProps={(state, rowInfo, column, instance) => {
														if (rowInfo !== undefined) {
																return {
																		onClick: (e, handleOriginal) => {
																			console.log('It was in this row:', rowInfo)
																			console.log('with ID:', rowInfo.original.categoryID);

																			//this.goToRepairLog(rowInfo.original.repairId);
																		},
																		style: {
																				cursor: 'pointer'
																		}
																}
														}}
												}
									>
									{(state, makeTable, instance) => {
				            let recordsInfoText = "";

				            const { filtered, pageRows, pageSize, sortedData, page } = state;

				            if (sortedData && sortedData.length > 0) {
				              let isFiltered = filtered.length > 0;

				              let totalRecords = sortedData.length;

											globalSortedData = sortedData;

											globalFiltered = filtered;

				              let recordsCountFrom = page * pageSize + 1;

				              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

				              if (isFiltered)
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
				              else
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
				            } else recordsInfoText = "No records";

				            return (
				              <div className="main-grid mt-2">
												{makeTable()}
												<Row className="mt-2 text-center">
													<Col md="12">
														<Label style={{fontSize:'15px'}} for=""><b>{recordsInfoText}</b></Label>
													</Col>
												</Row>
				              </div>
				            );
				          }}
				        	</ReactTable>
									</Fade>
								}
							</CardBody>
						</Card>
					</Col>
				</Row>
				<div>
					<Modal isOpen={this.state.modal11} toggle={this.toggle11}>
						<ModalHeader toggle={this.toggle11}>Create Part</ModalHeader>
						<ModalBody>
							<Form onSubmit={this.createBOMSubmit}>
								<FormGroup>
									<Label for="PartNumber">Part Number</Label>
									<Input type="text" name="PartNumber" id="PartNumber"/>
								</FormGroup>
								<FormGroup>
									<Label for="Cost">Cost</Label>
									<Input type="text" name="Cost" id="Cost"/>
								</FormGroup>
								<FormGroup>
									<Label for="Quantity">Quantity</Label>
									<Input type="text" name="Quantity" id="Quantity"/>
								</FormGroup>
								<FormGroup>
									<Label for="Description">Description</Label>
									<Input type="text" name="Description" id="Description"/>
								</FormGroup>
								<FormGroup>
									<Label className="pr-4" for="IgnoreQuantityFlag">Ignore Quantity Flag:</Label>
									<Input type="checkbox" name="IgnoreQuantityFlag" id="IgnoreQuantityFlag" onChange={this.ignoreQuantityFlagChange}/>
								</FormGroup>
								<FormGroup>
									<Label for="QuantityFlag">Quantity Flag</Label>
									<Input type="text" name="QuantityFlag" id="QuantityFlag" disabled={this.state.disableQuantityFlag}/>
								</FormGroup>
								<FormGroup>
									<Label for="Products">Select Products</Label>
									<Input id="Products" multiple name="Products" type="select">
										{this.state.productsData.map((product, i) => (
											<option value={product.id}>{product.Name}</option>
										))}
									</Input>
								</FormGroup>
								<FormGroup>
									<Button color="primary" type="submit">Save</Button>
									<Button color="secondary" className="ml-1" onClick={this.toggle11}>Cancel</Button>
								</FormGroup>
							</Form>
						</ModalBody>
					</Modal>
					<Modal isOpen={this.state.modal7} toggle={this.toggle7}>
						<ModalHeader toggle={this.toggle7}>Edit Part</ModalHeader>
						<ModalBody>
							<Form onSubmit={this.handleBOMSubmit}>
								<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
								<Input type="hidden" name="bomID" id="bomID" defaultValue={this.state.obj.bomID}/>
								<FormGroup>
									<Label for="PartNumber">Part Number</Label>
									<Input type="text" name="PartNumber" id="PartNumber" defaultValue={this.state.obj.PartNumber} onChange={this.costOrPartNumberChange}/>
								</FormGroup>
								<FormGroup>
									<Label for="Description">Description</Label>
									<Input type="text" name="Description" id="Description" defaultValue={this.state.obj.Description} onChange={this.costOrPartNumberChange}/>
								</FormGroup>
								<FormGroup>
									<Label for="Cost">Cost</Label>
									<Input type="text" name="Cost" id="Cost" defaultValue={this.state.obj.Cost} onChange={this.costOrPartNumberChange}/>
								</FormGroup>
								<FormGroup>
									<Label className="pr-4" for="IgnoreQuantityFlag">Ignore Quantity Flag:</Label>
									<Input type="checkbox" name="IgnoreQuantityFlag" id="IgnoreQuantityFlag" defaultChecked={this.state.obj.IgnoreQFlag==1 ? true : false} onChange={this.ignoreQuantityFlagChange}/>
								</FormGroup>
								<FormGroup>
									<Label for="QuantityFlag">Quantity Flag</Label>
									<Input type="text" name="QuantityFlag" id="QuantityFlag" defaultValue={this.state.obj.QuantityFlag} disabled={this.state.disableQuantityFlag}/>
								</FormGroup>
								<FormGroup>
							    <Label for="Products">Select Products</Label>
							    <Input id="Products" multiple name="selectMulti" type="select">
										{
											// {this.state.productsData.map((product, i) => (
											// 	<option value={product.id}>{product.Name}</option>
											// ))}
										}

										{this.state.productsData.map((product, i) => {

											var productItem = this.state.productItemsForBOM.find(p => p.ProductID === product.id);

											console.log('product: ', product.id);
											console.log('productItem: ', productItem);

											if (productItem) {
												return (
													<option value={product.id} selected>{product.Name}</option>
												);
											} else {
												return (
													<option value={product.id}>{product.Name}</option>
												);
											}

										})}
							    </Input>
							  </FormGroup>
								<Row>
									<Col md="4">
										<FormGroup>
											<Label for="Cost">Original Quantity</Label>
											<Input type="number" name="OriginalQuantity" id="OriginalQuantity" defaultValue={this.state.obj.Quantity} readonly="readonly"/>
										</FormGroup>
									</Col>
									<Col md="3">
										<FormGroup>
											<Label for="Cost">Adjustments</Label>
											<Row className="text-center">
												<Col md="6">
													<Button style={{ marginTop: "2px" }} color="yellow" size="sm" round="true" icon="true" onClick={()=>{this.subtractQuantity();}}>
														<i className="fa fa-minus" />
													</Button>
												</Col>
												<Col md="6">
													<Button style={{ marginTop: "2px" }} color="yellow" size="sm" round="true" icon="true" onClick={()=>{this.addQuantity();}}>
														<i className="fa fa-plus" />
													</Button>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col md="4">
									<FormGroup>
										<Label for="Cost">New Quantity</Label>
										{this.state.obj.Quantity == this.state.currentQuantityOfItem ? (
											<Input type="number" name="NewQuantity" id="NewQuantity" value={this.state.currentQuantityOfItem > 0 ? this.state.currentQuantityOfItem : 0} onChange={this.onChangeNewQuantity}/>
										) : (
											<Input style={{ border: "3px solid #FFC108" }} type="number" name="NewQuantity" id="NewQuantity" value={this.state.currentQuantityOfItem > 0 ? this.state.currentQuantityOfItem : 0} onChange={this.onChangeNewQuantity}/>
										)}
									</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md="3">
									</Col>
									<Col md="5">
										<FormGroup>
											<Row className="text-center">
												<Col md="6">
													<FormGroup>
														{this.state.obj.Quantity <= this.state.currentQuantityOfItem ? (
															<Input style={{ width: "80px" }} type="number" name="SubtractQuantity" id="SubtractQuantity" value={(this.state.obj.Quantity <= this.state.currentQuantityOfItem) ? 0 : (this.state.obj.Quantity - this.state.currentQuantityOfItem)} onChange={this.onChangeSubtractQuantity}/>
														) : (
															<Input style={{ border: "3px solid #FFC108" }} type="number" name="SubtractQuantity" id="SubtractQuantity" value={(this.state.obj.Quantity <= this.state.currentQuantityOfItem) ? 0 : (this.state.obj.Quantity - this.state.currentQuantityOfItem)} onChange={this.onChangeSubtractQuantity}/>
														)}
													</FormGroup>
												</Col>
												<Col md="6">
													<FormGroup>
														{this.state.obj.Quantity >= this.state.currentQuantityOfItem ? (
															<Input type="number" name="AddQuantity" id="AddQuantity" value={(this.state.currentQuantityOfItem <= this.state.obj.Quantity) ? 0 : (this.state.currentQuantityOfItem - this.state.obj.Quantity)} onChange={this.onChangeAddQuantity}/>
														) : (
															<Input style={{ border: "3px solid #FFC108" }} type="number" name="AddQuantity" id="AddQuantity" value={(this.state.currentQuantityOfItem <= this.state.obj.Quantity) ? 0 : (this.state.currentQuantityOfItem - this.state.obj.Quantity)} onChange={this.onChangeAddQuantity}/>
														)}
													</FormGroup>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col md="3">
									</Col>
								</Row>
								<Fragment>
									<FormGroup>
										<Label style={{fontSize:'18px'}} for="AdjustmentReason">Reason for Adjustment</Label>
										<Input style={{height: '30px', padding: '3px'}} type="select" name="AdjustmentReason" id="AdjustmentReason" value={this.state.reasonAdjustmentSelection} onChange={this.onChangeAdjustmentReason}>
											<option value="0">Select One</option>
											{this.state.adjustmentReasons.map((reason, i) => (
												<option value={i+1}>{reason.name}</option>
											))}
											<option value={this.state.adjustmentReasons.length+1}>Other</option>
										</Input>
									</FormGroup>
									{this.state.reasonAdjustmentSelection==(this.state.adjustmentReasons.length+1) &&
										<FormGroup>
											<Input type="text" name="OtherAdjustmentReason" id="OtherAdjustmentReason" defaultValue="" placeholder="Enter Other Reason" />
										</FormGroup>
									}
								</Fragment>
								<FormGroup>
									<Label style={{fontSize:'18px'}} for="AuthorizedBy">Authorized By</Label>
									<Input style={{height: '30px', padding: '3px'}} type="select" name="AuthorizedBy" id="AuthorizedBy" value={this.state.currentDataSetting2} onChange={this.onChangeAuthorizedBy}>
										{this.state.availableUsers.map((user, i) => (
											<option value={i}>{user.name}</option>
										))}
									</Input>
								</FormGroup>
								{(this.state.obj.Cost != this.state.newCost || this.state.obj.PartNumber != this.state.newPartNumber || this.state.obj.Description!=this.state.newDescription) &&
									<FormGroup>
										<Label style={{fontSize:'18px'}} for="AuthPassword">Authorization Password</Label>
										<Input type="password" name="AuthPassword" id="AuthPassword" placeholder="Password" onChange={this.onChangeAuthPassword} />
									</FormGroup>
								}
								{this.state.submitStatus &&
									<div className={'alert alert-danger'}>{this.state.submitStatus}</div>
								}
								<FormGroup>
									<Button color="primary" type="submit">Save</Button>
									<Button color="secondary" className="ml-1" onClick={this.toggle7}>Cancel</Button>
								</FormGroup>
							</Form>
						</ModalBody>
					</Modal>
					<Modal isOpen={this.state.modal8} toggle={this.toggle8}>
	  				<ModalHeader toggle={this.toggle8}>Delete Part</ModalHeader>
	  				<ModalBody>
							<span>Are you sure you want to delete this Part?</span>
	  				</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={this.deleteBOM}>Yes</Button>
							<Button color="secondary" className="ml-1" onClick={this.toggle8}>Cancel</Button>
						</ModalFooter>
				  </Modal>
					<Modal isOpen={this.state.modal1} toggle={this.toggle1}>
						<ModalHeader toggle={this.toggle1}>Create Asset</ModalHeader>
						<ModalBody>
							<Form onSubmit={this.createAssetSubmit}>
								<FormGroup>
									<Label for="PartNumber">Part Number</Label>
									<Input type="text" name="PartNumber" id="PartNumber"/>
								</FormGroup>
								<FormGroup>
									<Label for="SerialNumber">Serial Number</Label>
									<Input type="text" name="SerialNumber" id="SerialNumber"/>
								</FormGroup>
								<FormGroup>
									<Label for="Cost">Cost</Label>
									<Input type="text" name="Cost" id="Cost"/>
								</FormGroup>
								<FormGroup>
									<Label for="Quantity">Quantity</Label>
									<Input type="text" name="Quantity" id="Quantity"/>
								</FormGroup>
								<FormGroup>
									<Label for="Description">Description</Label>
									<Input type="text" name="Description" id="Description"/>
								</FormGroup>
								<FormGroup>
									<Label for="MarketID">Market</Label>
									<Input name="MarketID" id="MarketID" style={{height: '30px', padding: '3px'}} type="select">
										{this.state.marketsData.map((market, i) => {
											return (
												<option value={market.id}>{market.Name}</option>
											);
										})}
									</Input>
								</FormGroup>
								<FormGroup>
									<Label for="CalibrationDate">Calibration Date</Label>
									<Input style={{ 'color-scheme': 'dark' }} type="date" name="CalibrationDate" id="CalibrationDate"/>
								</FormGroup>
								<FormGroup>
									<Label for="CalibrationPeriod">Calibration Period (Months)</Label>
									<Input type="text" name="CalibrationPeriod" id="CalibrationPeriod"/>
								</FormGroup>
								<FormGroup>
									<Label for="DeliveryDate">Delivery Date</Label>
									<Input style={{ 'color-scheme': 'dark' }} type="date" name="DeliveryDate" id="DeliveryDate"/>
								</FormGroup>
								<FormGroup>
									<Button color="primary" type="submit">Save</Button>
									<Button color="secondary" className="ml-1" onClick={this.toggle1}>Cancel</Button>
								</FormGroup>
							</Form>
						</ModalBody>
					</Modal>
					<Modal isOpen={this.state.modal2} toggle={this.toggle2}>
						<ModalHeader toggle={this.toggle2}>Edit Asset</ModalHeader>
						<ModalBody>
							<Form onSubmit={this.editAssetSubmit}>
								<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
								<Input type="hidden" name="assetID" id="assetID" defaultValue={this.state.obj.assetID}/>
								<FormGroup>
									<Label for="PartNumber">Part Number</Label>
									<Input type="text" name="PartNumber" id="PartNumber" defaultValue={this.state.obj.PartNumber}/>
								</FormGroup>
								<FormGroup>
									<Label for="SerialNumber">Serial Number</Label>
									<Input type="text" name="SerialNumber" id="SerialNumber" defaultValue={this.state.obj.SerialNumber}/>
								</FormGroup>
								<FormGroup>
									<Label for="Cost">Cost</Label>
									<Input type="text" name="Cost" id="Cost" defaultValue={this.state.obj.Cost}/>
								</FormGroup>
								<FormGroup>
									<Label for="Quantity">Quantity</Label>
									<Input type="text" name="Quantity" id="Quantity" defaultValue={this.state.obj.Quantity}/>
								</FormGroup>
								<FormGroup>
									<Label for="Description">Description</Label>
									<Input type="text" name="Description" id="Description" defaultValue={this.state.obj.Description}/>
								</FormGroup>
								<FormGroup>
									<Label for="MarketID">Market</Label>
									<Input name="MarketID" id="MarketID" style={{height: '30px', padding: '3px'}} type="select" defaultValue={this.state.obj.MarketID}>
										{this.state.marketsData.map((market, i) => {
											return (
												<option value={market.id}>{market.Name}</option>
											);
										})}
									</Input>
								</FormGroup>
								<FormGroup>
									<Label for="CalibrationDate">Calibration Date</Label>
									<Input style={{ 'color-scheme': 'dark' }} type="date" name="CalibrationDate" id="CalibrationDate" defaultValue={Moment(this.state.obj.CalibrationDate).utc(false).format('YYYY-MM-DD')}/>
								</FormGroup>
								<FormGroup>
									<Label for="CalibrationPeriod">Calibration Period (Months)</Label>
									<Input type="text" name="CalibrationPeriod" id="CalibrationPeriod" defaultValue={this.state.obj.CalibrationPeriod}/>
								</FormGroup>
								<FormGroup>
									<Label for="DeliveryDate">Delivery Date</Label>
									<Input style={{ 'color-scheme': 'dark' }} type="date" name="DeliveryDate" id="DeliveryDate" defaultValue={Moment(this.state.obj.DeliveryDate).utc(false).format('YYYY-MM-DD')}/>
								</FormGroup>
								<FormGroup>
									<Button color="primary" type="submit">Save</Button>
									<Button color="secondary" className="ml-1" onClick={this.toggle2}>Cancel</Button>
								</FormGroup>
							</Form>
						</ModalBody>
					</Modal>
					<Modal isOpen={this.state.modal3} toggle={this.toggle3}>
						<ModalHeader toggle={this.toggle3}>Delete Asset</ModalHeader>
						<ModalBody>
							<span>Are you sure you want to delete this Asset?</span>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={this.deleteAsset}>Yes</Button>
							<Button color="secondary" className="ml-1" onClick={this.toggle3}>Cancel</Button>
						</ModalFooter>
					</Modal>
				</div>
				<ToastContainer/>
			</Fragment>
		);
	}
}

export default InventoryManagementList;
