import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
  Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown,
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';
import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
var moment = require('moment-timezone');

let lineOptions2 = {
	maintainAspectRatio: false,
	legend: {
		display: false,
		labels: { fontFamily: "Nunito Sans" }
	},
	scales: {
		yAxes: [{
			gridLines: {
				display: false
			},
			ticks: {
				fontFamily: "Nunito Sans",
				fontColor: 'rgb(178, 185, 191)'
			}
		}],
		xAxes: [{
			gridLines: {
				display: false
			},
			ticks: {
				fontFamily: "Nunito Sans",
				fontColor: 'rgb(178, 185, 191)'
			}
		}]
	}
}

class ComponentStatisticsView extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			repairLogs: [],
      repairGraphData: [],
      repairCategories: [],
      repairOptions: [],
			bomData: [],
			replacedComponentsData: [],
			productsData: [],
      batchesData: [],
			marketsData: [],
			productSelect: "ALL",
			batchSelect: "ALL",
			marketSelect: "ALL",
			pages: -1,
			loading: false,
			pageSize: 10,
			currentPage: 1,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			searchTerm: "",
			startDate: "",
			endDate: "",
			currentDate: Moment().format('YYYY-MM-DD'),
      searchByDate: false,
			currentDataSetting: 0,
      rSelected: 1,
			r2Selected: 0,
			totalResults: 0,
      isOpen: false,
			currentItem: "Table",
      searchSelection: 0,
			productList: ["All", "Gemini", "EVO"],
			batchList: ["All", "320", "500", "504", "510", "520", "530", "AZENA", "Giraffe", "Hippo", "Iguana", "Jaguar", "Koala", "Lion", "Mercury", "Monkey"],
      legacyRepairOptions: [
        "ReplacedSD",
        "ReplacedFiber",
        "ReplacedModule",
        "ReplacedMain",
        "ReplacedHans",
        "ReplacedDisplay",
        "ReplacedSpeaker",
        "ReplacedBattery",
        "ReplacedPedal",
        "ReplacedKeypad",
        "ReplacedDisplayDriver",
        "FixedBoardSolder",
        "ReplacedFlex16",
        "ReplacedFlex21",
        "ReplacedBox",
        "ReplacedFoam",
        "ReplacedButtons",
        "ReplacedHandpiece",
        "ReplacedLens",
        "ReplacedTouchPCB",
        "ReplacedFootpedalPCB",
        "ReplacedOverlay",
        "ReplacedPlastics",
        "ReplacedUndetermined",
      ]
	  };

		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
    this.onChangeSearchSelection = this.onChangeSearchSelection.bind(this);
    this.toggleDropDown = this.toggleDropDown.bind(this);

    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
		this.onRadioBtnClick2 = this.onRadioBtnClick2.bind(this);

  }

	async componentDidMount() {
		this.getProductsData();
    this.getBatchData();
		this.getMarketsData();
		this.getReplacedComponentsData();

    //this.getData(this.state.currentPage, this.state.pageSize);
    this.searchData(this.state.currentPage, this.state.pageSize);
    this.getCategoryData();
    this.getOptionsData();
	}

  async onRadioBtnClick(rSelected) {
		this.setState({ rSelected, searchSelection: 0 });
		//await this.getData(rSelected);
	}

	async onRadioBtnClick2(r2Selected) {
		this.setState({ r2Selected, searchSelection: 0 });
		this.setState({ rSelected: 1 });
		//await this.getData(rSelected);
	}

  toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		//setTimeout(this.getData, 1000);
  }

	getProductsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-products";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Products data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productsData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  getBatchData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-batches";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Batches data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ batchesData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  onChangeSearchSelection = (event) => {

		console.log("onChangeSelection input id: " + event.target.id);
		console.log("onChangeSelection input value: " + event.target.value);

		let id = event.target.id;
		let value = event.target.value;

		this.setState({ searchSelection: value });

    setTimeout(() => this.searchData(1, this.state.pageSize), 1000);

	}

  getCategoryData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-repair-categories";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all repairCategories: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ repairCategories: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getOptionsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-repair-options";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all repairOptions: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ repairOptions: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getReplacedComponentsData = async () => {

		var productID = 0;

		if (this.state.productSelect!="ALL") {
			const product = this.state.productsData.find(product => product.Name==this.state.productSelect);
			productID = product.id;
		}


		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-bill-of-materials?ProductID="+productID;
		console.log("getReplacedComponentsData url: " + url);

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all replacedComponentsData data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ replacedComponentsData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	onChangeProduct = (event) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);

		let id = event.target.id;
		let value = event.target.value;

		//console.log("repairLog: " + JSON.stringify(repairLog));

		this.setState({ productSelect: value });
		this.setState({ batchSelect: "ALL" });

		setTimeout(() => this.searchData(1, this.state.pageSize), 1000);

	}

	onChangeBatch = (event) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);

		let id = event.target.id;
		let value = event.target.value;

		//console.log("repairLog: " + JSON.stringify(repairLog));

		this.setState({ batchSelect: value });

		setTimeout(() => this.searchData(1, this.state.pageSize), 1000);

	}

	onChangeMarket = (event) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);

		let id = event.target.id;
		let value = event.target.value;

		//console.log("repairLog: " + JSON.stringify(repairLog));

		this.setState({ marketSelect: value });

		setTimeout(() => this.searchData(1, this.state.pageSize), 1000);

	}

	componentWillUnmount() {
		clearInterval(this.intervalID);
	}

	handleChange(event) {
		if (event.target.getAttribute('name') == 'startDate') {
			this.setState({startDate: event.target.value});
			this.setState({
				searchByDate: true
	    });
		} else if (event.target.getAttribute('name') == 'endDate') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({endDate: event.target.value});
			this.setState({
				searchByDate: true
	    });
		}

		//this.searchData("", 1, this.state.pageSize);
		setTimeout(() => this.searchData(1, this.state.pageSize), 1000);
  }

	onChange() {
		var searchTerm = document.getElementById("SearchTerm").value;

		clearInterval(this.intervalID);

		this.setState({ currentPage: 1 });

		if (searchTerm && searchTerm != "") {
			console.log("searchTerm: " + searchTerm);
			this.searchData(searchTerm, 1, this.state.pageSize);
			//this.intervalID = setInterval(()=>{ this.searchData(searchTerm, 1, this.state.pageSize); }, 5000);
		}
	}

	clearSearch() {

		clearInterval(this.intervalID);

		document.getElementById("SearchTerm").value = "";

		this.setState({ currentPage: 1, currentDataSetting:0 });
		this.setState({ startDate: "" });
		this.setState({ endDate: "" });
		//this.getData(1, this.state.pageSize);
	}

	goToRepairLog(id){
		//window.location.href="/repair-log/"+id;
		window.open(
		  "/repair-log/"+id,
		  '_blank'
		);
	}

  getGraphData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-total-repairs-by-batch";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("top pending repairLogs: " + JSON.stringify(body));

			this.setState({loading: false});

			if (body.status == 'Success') {

	      var chartData = {
						labels: body.response.map(k => k.BatchNum),
						datasets: [{
							label: 'Total Repairs',
							backgroundColor: '#e9c736',
							data: body.response.map(k => k.total)
						}]
					}

	      this.setState({ repairGraphData: chartData });

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	searchData = async (currentPage, pageSize) => {

		var timezone = Moment.tz.guess();

		try {

			//https://cqzybp52y3.execute-api.us-west-2.amazonaws.com/v1/search-for-component?OptionType=0&ProductName=ALL&BatchName=ALL&SearchValue=36&SearchType=2&CurrentPage=1&PageSize=10&StartDate=2023-01-01&EndDate=2023-02-09&Timezone=America/Los_Angeles
			if (!this.state.searchByDate) {
				var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"search-for-component?OptionType="+this.state.r2Selected+"&ProductName="+this.state.productSelect+"&BatchName="+this.state.batchSelect+"&MarketName="+this.state.marketSelect+"&SearchValue="+this.state.searchSelection+"&SearchType="+this.state.rSelected+"&CurrentPage="+currentPage+"&PageSize="+pageSize;
				console.log("searchData url: " + url);

				try {

					const response = await fetch(url, {
						method: 'GET',
						headers: {
							Authorization: this.state.currentUser.jwtToken
						},
					});

					const body = await response.json();
					console.log("all repairLogs: " + JSON.stringify(body));

					this.setState({loading: false});

					if (body.status == 'Success') {
						this.setState({ repairLogs: body.response, pages: body.pages, totalResults: body.totalrecords });

		        var chartData = {
		  					labels: body.graphResults.map(k => k.BatchNum),
		  					datasets: [{
		  						label: 'Total Repairs',
		  						backgroundColor: '#e9c736',
		  						data: body.graphResults.map(k => k.total)
		  					}]
		  				}

		        console.log("chartData: " + JSON.stringify(chartData));

		        this.setState({ repairGraphData: chartData });
					} else {
			      //ADD ERROR MESSAGE
			    }

				}
				catch (err) {
					console.log(err);
					authenticationService.refresh();
				}


			} else {

				var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"search-for-component?OptionType="+this.state.r2Selected+"&ProductName="+this.state.productSelect+"&BatchName="+this.state.batchSelect+"&MarketName="+this.state.marketSelect+"&SearchValue="+this.state.searchSelection+"&SearchType="+this.state.rSelected+"&CurrentPage="+currentPage+"&PageSize="+pageSize+"&StartDate="+this.state.startDate+"&EndDate="+this.state.endDate+"&Timezone="+timezone;

				//var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"search-for-component-by-date-range?SearchValue="+this.state.searchSelection+"&SearchType="+this.state.rSelected+"&CurrentPage="+currentPage+"&PageSize="+pageSize+"&StartDate="+this.state.startDate+"&EndDate="+this.state.endDate;
				console.log("url: " + url);

				try {

					const response = await fetch(url, {
						method: 'GET',
						headers: {
							Authorization: this.state.currentUser.jwtToken
						},
					});

					const body = await response.json();
					console.log("all by date repairLogs: " + JSON.stringify(body));

					this.setState({loading: false});

					if (body.status == 'Success') {
						this.setState({ repairLogs: body.response, pages: body.pages, totalResults: body.totalrecords });

		        var chartData = {
		  					labels: body.graphResults.map(k => k.BatchNum),
		  					datasets: [{
		  						label: 'Total Repairs',
		  						backgroundColor: '#e9c736',
		  						data: body.graphResults.map(k => k.total)
		  					}]
		  				}

		        console.log("chartData: " + JSON.stringify(chartData));

		        this.setState({ repairGraphData: chartData });
					} else {
			      //ADD ERROR MESSAGE
			    }

				}
				catch (err) {
					console.log(err);
					authenticationService.refresh();
				}

			}

		} catch (err) {
			console.log("searchData error: "+err);
		}

	};

	getMarketsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-markets";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all markets data: " + JSON.stringify(body));

			if (body.status == 'Success') {

				let userMarket = this.state.currentUser.payload['custom:market'];
				var marketsData = body.response;

				if (userMarket=='aus' || userMarket=='europe' || userMarket=='brazil' || userMarket=='taiwan' || userMarket=='colombia') {
					marketsData = marketsData.filter(market => market.Abbreviation.includes(userMarket))
				}

				this.setState({ marketsData });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  render() {

		var batchesList = [];

		if (this.state.productSelect!="ALL") {

			let currentProduct = this.state.productsData.find(product => product.Name==this.state.productSelect);

			if (currentProduct) {
				batchesList = this.state.batchesData.filter(batch => batch.ProductID==currentProduct.id);
			}

		} else {

			batchesList = this.state.batchesData;

		}

		var marketsList = this.state.marketsData;

		const data = this.state.repairLogs.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedrepair, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				repairId: prop.ID,
				productName: prop.ProductName,
				serialnum: prop.SerialNum,
				batchnum: prop.BatchNum,
				datecreated: Moment(prop.DateCreated).format('MMM Do YYYY'),
				market: prop.market,
				Status: prop.Status,
				UPIClosed: prop.UPIClosed,
				CustomerName: prop.CustomerName,
				TrackingNum: prop.TrackingNum
			};
		});

		return (
      <Card className="card-hover">
				<CardBody>
          <Row className="">
						<Col md="2">
							<Row>
								<Col xs="12" md="12" lg="12" className="">
									<h2 style={{color:'#737782'}}>Record Search</h2>
								</Col>
							</Row>
						</Col>
						<Col md="2">
							<Row>
								<Col xs="12" md="12" lg="12" className="">
									<ButtonGroup size="sm" style={{ width: '100%' }}>
											<Button
												color="dark"
												style={{width: '100%'}}
												onClick={() => this.onRadioBtnClick2(0)}
												active={this.state.r2Selected === 0}
											>
												Current
											</Button>
											<Button
												color="dark"
												style={{width: '100%'}}
												onClick={() => this.onRadioBtnClick2(1)}
												active={this.state.r2Selected === 1}
											>
												Legacy
											</Button>
										</ButtonGroup>
								</Col>
							</Row>
							<Row>
								<Col xs="12" md="12" lg="12" className="mt-1">
									<div className="float-left text-right" style={{ width: '60px' }}>
										<h6 style={{ lineHeight: '1.9' }}>Market:</h6>
									</div>
									<div className="float-left ml-2" style={{ width: '140px' }}>
										<Input style={{height: '30px', padding: '3px'}} type="select" name="marketSelect" id="marketSelect" value={this.state.marketSelect} onChange={this.onChangeMarket}>
											<option value="ALL">ALL</option>
											{marketsList.map((market, i) => (
												<option value={market.Abbreviation}>{market.Name}</option>
											))}
										</Input>
									</div>
								</Col>
							</Row>
            </Col>
						<Col md="2" className="">
							<Row className="ml-2">
								<Col xs="12" md="12" lg="12" className="">
									<div className="float-left text-right" style={{ width: '60px' }}>
										<h6 style={{ lineHeight: '1.9' }}>Product:</h6>
									</div>
									<div className="float-left ml-2" style={{ width: '140px' }}>
										<Input style={{height: '30px', padding: '3px'}} type="select" name="productSelect" id="productSelect" value={this.state.productSelect} onChange={this.onChangeProduct}>
											<option value="ALL">ALL</option>
											{this.state.productsData.map((product, i) => (
												<option value={product.Name}>{product.Name}</option>
											))}
										</Input>
									</div>
								</Col>
							</Row>
							<Row className="ml-2">
								<Col xs="12" md="12" lg="12" className="">
									<div className="float-left text-right" style={{ width: '60px' }}>
										<h6 style={{ lineHeight: '1.9' }}>Batch:</h6>
									</div>
									<div className="float-left ml-2" style={{ width: '140px' }}>
										<Input style={{height: '30px', padding: '3px'}} type="select" name="batchSelect" id="batchSelect" value={this.state.batchSelect} onChange={this.onChangeBatch}>
											<option value="ALL">ALL</option>
											{batchesList.map((batch, i) => (
												<option value={batch.Name}>{batch.Name}</option>
											))}
										</Input>
									</div>
								</Col>
							</Row>
						</Col>
						<Col md="2" className="">
							<Row className="ml-2">
								<Col xs="12" md="12" lg="12" className="">
									<div className="float-left text-right" style={{ width: '50px' }}>
										<h6 style={{ lineHeight: '1.9' }}>From:</h6>
									</div>
									<div className="float-left ml-2" style={{ width: '140px' }}>
										<Input style={{width:'100%', height: '30px'}} name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
									</div>
								</Col>
							</Row>
							<Row className="ml-2">
								<Col xs="12" md="12" lg="12" className="">
									<div className="float-left text-right" style={{ width: '50px' }}>
										<h6 style={{ lineHeight: '1.9' }}>To:</h6>
									</div>
									<div className="float-left ml-2" style={{ width: '140px' }}>
										<Input style={{width:'100%', height: '30px'}} name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
									</div>
								</Col>
							</Row>
						</Col>
						<Col md="3" className="text-left">
							{this.state.r2Selected==0 ? (
								<Row>
									<Col xs="12" md="12" lg="12" className="">
	                  <ButtonGroup size="sm" style={{ width: '100%' }}>
	                      <Button
	                        color="dark"
	                        style={{width: '100%'}}
	                        onClick={() => this.onRadioBtnClick(1)}
	                        active={this.state.rSelected === 1}
	                      >
	                        Replaced Components
	                      </Button>
	                      <Button
	                        color="dark"
	                        style={{width: '100%'}}
	                        onClick={() => this.onRadioBtnClick(2)}
	                        active={this.state.rSelected === 2}
	                      >
	                        Repair Reasons
	                      </Button>
	                      <Button
	                        style={{width: '100%'}}
	                        color="dark"
	                        onClick={() => this.onRadioBtnClick(3)}
	                        active={this.state.rSelected === 3}
	                      >
	                        Repair Options
	                      </Button>
	                    </ButtonGroup>
									</Col>
								</Row>
							) : (
								<Row>
									<Col xs="12" md="12" lg="12" className="">
	                  <ButtonGroup size="sm" style={{ width: '100%' }}>
	                      <Button
	                        color="dark"
	                        style={{width: '100%'}}
	                        onClick={() => this.onRadioBtnClick(1)}
	                        active={this.state.rSelected === 1}
	                      >
	                        Repair Options
	                      </Button>
	                      <Button
	                        color="dark"
	                        style={{width: '100%'}}
	                        onClick={() => this.onRadioBtnClick(2)}
	                        active={this.state.rSelected === 2}
	                      >
	                        Report Categories
	                      </Button>
	                    </ButtonGroup>
									</Col>
								</Row>
							)}
							<Row className="pt-2">
                <Col xs="3" md="3" lg="3" className="">
                  <Label style={{fontSize:'18px'}} for="SearchSelection">SEARCH:</Label>
                </Col>
								<Col xs="9" md="9" lg="9" className="">
                  <Input style={{height: '30px', padding: '3px'}} type="select" name="SearchSelection" id="SearchSelection" value={this.state.searchSelection} onChange={this.onChangeSearchSelection}>
                  <option value="Select">Select Component</option>
									{this.state.r2Selected === 0 ? (
										<Fragment>
											{this.state.rSelected === 1 &&
												<Fragment>
												{this.state.replacedComponentsData.map((replacedComponent, i) => (
													<option value={replacedComponent.id}>{replacedComponent.Description}</option>
												))}
												</Fragment>
											}
											{this.state.rSelected === 2 &&
												<Fragment>
												{this.state.repairCategories.map((category, i) => (
													<option value={category.id}>{category.name}</option>
												))}
												</Fragment>
											}
											{this.state.rSelected === 3 &&
												<Fragment>
												{this.state.repairOptions.map((option, i) => (
													<option value={option.id}>{option.name}</option>
												))}
												</Fragment>
											}
										</Fragment>
									) : (
										<Fragment>
											{this.state.rSelected === 1 &&
												<Fragment>
												{this.state.legacyRepairOptions.map((component, i) => (
													<option value={component}>{component}</option>
												))}
												</Fragment>
											}
											{this.state.rSelected === 2 &&
												<Fragment>
												{this.state.repairCategories.map((category, i) => (
													<option value={category.id}>{category.name}</option>
												))}
												</Fragment>
											}
										</Fragment>
									)}
                  </Input>
								</Col>
							</Row>
            </Col>
            <Col md="1" className="">
              <Row className="pt-2">
                <Col xs="12" md="12" lg="12" className="">
                  <Label style={{lineHeight:'0.0'}} for="">View As:</Label>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col xs="12" md="12" lg="12" className="">
                  <ButtonDropdown size="sm" isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
                    <DropdownToggle color="dark" caret>
                      {this.state.currentItem}
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={() => this.selectDropDownItem(0, 'Table')}>Table</DropdownItem>
                      <DropdownItem onClick={() => this.selectDropDownItem(1, 'Graph')}>Graph</DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </Col>
              </Row>
						</Col>
          </Row>
          <Row className="">
            <Col xs="12" md="12" lg="12" className="">
              {this.state.currentDataSetting==0 ? (
                <Fragment>
                  <ReactTable
        					  style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
        						columns={[
        							{
        								Header: "ID",
        								accessor: "repairId",
        								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
        								minWidth: 15,
        								Cell: row => (
        					          <div style={{ textAlign: "center" }}>{row.value}</div>
        					        )
        							},
        							{
        								Header: "Serial Number",
        								accessor: "serialnum",
        								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
        								minWidth: 25,
        								Cell: row => (
        					          <div style={{ textAlign: "center" }}>{row.value}</div>
        					        )
        							},
											{
        								Header: "Product",
        								accessor: "productName",
        								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
        								minWidth: 25,
        								Cell: row => (
        					          <div style={{ textAlign: "center" }}>{row.value}</div>
        					        )
        							},
        							{
        								Header: "Batch Number",
        								accessor: "batchnum",
        								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
        								minWidth: 25,
        								Cell: row => (
        					          <div style={{ textAlign: "center" }}>{row.value}</div>
        					        )
        							},
        							{
        								Header: "Creation Date",
        								accessor: "datecreated",
        								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
        								minWidth: 25,
        								Cell: row => (
        					          <div style={{ textAlign: "center" }}>{row.value}</div>
        					        )
        							},
        							{
        								Header: "Market",
        								accessor: "market",
        								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
        								minWidth: 15,
        								Cell: row => (
        					          <div style={{ textAlign: "center" }}>{row.value}</div>
        					        )
        							},
        							{
        								Header: "UPI Closed?",
        								accessor: "UPIClosed",
        								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
        								minWidth: 35,
        								Cell: row => (

        									<div style={{ textAlign: "center" }}>
        										{row.value==1 ? (
        											<i style={{color: '#21c603'}} className="mdi mdi-checkbox-marked-outline mdi-24px"/>
        										) : (
        											<i style={{color: '#f62d51'}} className="mdi mdi-close-outline mdi-24px"/>
        										)}
        									</div>

        					        )
        							},
        							{
        								Header: "Customer Name",
        								accessor: "CustomerName",
        								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
        								minWidth: 50,
        								Cell: row => (
        					          <div style={{ textAlign: "center" }}>{row.value}</div>
        					        )
        							},
        							{
        								Header: "Tracking #",
        								accessor: "TrackingNum",
        								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
        								minWidth: 40,
        								Cell: row => (
        					          <div style={{ textAlign: "center" }}>{row.value}</div>
        					        )
        							}
        						]}
        						defaultPageSize={this.state.pageSize}
        						showPaginationBottom={true}
        						className="-striped -highlight"
        						data={data}
        						pages={this.state.pages}
        						loading={this.state.loading}
        						onFetchData={(state, instance) => {
        					    // show the loading overlay
        					    this.setState({loading: true});
        					    // fetch your data
        							console.log("page: "+state.page+1);
        							console.log("pageSize: "+state.pageSize);
        							console.log("sorted: "+state.sorted);
        							console.log("filtered: "+state.filtered);

        							//var searchTerm = document.getElementById("SearchTerm").value;

                      this.searchData(state.page+1, state.pageSize);

        							// if (document.getElementById("SearchSelection") && document.getElementById("SearchSelection").value != "") {
        							// 	console.log("searchTerm: " + document.getElementById("SearchTerm").value);
        							// 	this.searchData(document.getElementById("SearchTerm").value, state.page+1, state.pageSize);
        							// } else {
        							// 	this.getData(state.page+1, state.pageSize);
        							// }

        					  }}
        						manual
        						getTrGroupProps={(state, rowInfo, column, instance) => {
                            if (rowInfo !== undefined) {
                                return {
                                    onClick: (e, handleOriginal) => {
                                      console.log('It was in this row:', rowInfo);
        															console.log('with ID:', rowInfo.original.repairId);

        															this.goToRepairLog(rowInfo.original.repairId);
                                    },
                                    style: {
                                        cursor: 'pointer'
                                    }
                                }
                            }}
                        }
        					/>
									<Row className="mt-2 text-center">
										<Col md="12">
											<Label style={{fontSize:'15px'}} for=""><b>Records Found: {this.state.totalResults}</b></Label>
										</Col>
									</Row>
                </Fragment>
              ) : (
                <Fragment>
                  <Bar height={463} data={this.state.repairGraphData} options={lineOptions2} />
                </Fragment>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
		);
	}
}

export default ComponentStatisticsView;
